type settingType = {
  value: string;
  label: string;
};

export const US_PHARMACIST_PRACTICE_SETTINGS: Array<settingType> = [
  {
    value: 'community_retail',
    label: 'Community/Retail Pharmacist',
  },
  {
    value: 'hospital',
    label: 'Hospital Pharmacist',
  },
  {
    value: 'clinical_specialty',
    label: 'Clinical/Specialty Pharmacist',
  },
  {
    value: 'long_term_care',
    label: 'Long-Term Care Pharmacist',
  },
  {
    value: 'industry',
    label: 'Industry Pharmacist',
  },
  {
    value: 'general_practice_primary_care',
    label: 'General Practice (GP) / Primary Care Pharmacist',
  },
  {
    value: 'distributor_wholesale',
    label: 'Distributor/Wholesale Pharmacist',
  },
  {
    value: 'consultant',
    label: 'Consultant Pharmacist',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const US_PHARMACIST_PRACTICE_SPECIALTIES: Array<settingType> = [
  {
    value: 'ambulatory_care',
    label: 'Ambulatory care',
  },
  {
    value: 'oncology',
    label: 'Oncology',
  },
  {
    value: 'infectious_disease',
    label: 'Infectious disease',
  },
  {
    value: 'pediatrics',
    label: 'Pediatrics',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const US_PHARMACY_RETAIL_CHAINS: Array<settingType> = [
  {
    value: 'walgreens',
    label: 'Walgreens',
  },
  {
    value: 'cvs',
    label: 'CVS',
  },
  {
    value: 'walmart',
    label: 'Walmart',
  },
  {
    value: 'rite_aid',
    label: 'Rite Aid',
  },
  {
    value: 'kroger',
    label: 'Kroger',
  },
  {
    value: 'albertsons',
    label: 'Albertsons',
  },
  {
    value: 'amerisource_bergen',
    label: 'AmerisourceBergen',
  },
  {
    value: 'publix',
    label: 'Publix',
  },
  {
    value: 'mc_kesson',
    label: 'McKesson',
  },
  {
    value: 'costco',
    label: 'Costco',
  },
  {
    value: 'cardinal_health',
    label: 'Cardinal Health',
  },
  {
    value: 'other',
    label: 'Other retail pharmacy chain',
  },
  {
    value: 'independent_pharmacy',
    label: 'I operate an independent pharmacy',
  },
];

export const CA_PHARMACIST_PRACTICE_SETTINGS: Array<settingType> = [
  {
    value: 'retail',
    label: 'Retail',
  },
  {
    value: 'hospital',
    label: 'Hospital Pharmacist',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const CA_PHARMACY_RETAIL_CHAINS: Array<settingType> = [
  {
    value: 'shoppers-drug-mart',
    label: 'Shoppers Drug Mart',
  },
  {
    value: 'pharma_save',
    label: 'PharmaSave',
  },
  {
    value: 'rexall',
    label: 'Rexall',
  },
  {
    value: 'walmart',
    label: 'Wal-Mart',
  },
  {
    value: 'costco',
    label: 'Costco',
  },
  {
    value: 'famili_prix',
    label: 'Famili-Prix',
  },
  {
    value: 'jean_coutu',
    label: 'Jean Coutu',
  },
  {
    value: 'uniprix',
    label: 'Uniprix',
  },
  {
    value: 'remedy-rx',
    label: 'Remedy’s RX',
  },
  {
    value: 'guardian-drugs',
    label: 'Guardian Drugs',
  },
  {
    value: 'ida',
    label: 'IDA',
  },
  {
    value: 'loblaws',
    label: 'Loblaws',
  },
  {
    value: 'pharmachoice',
    label: 'Pharmachoice',
  },
  {
    value: 'whole-health',
    label: 'Whole Health',
  },
  {
    value: 'other',
    label: 'Other retail pharmacy chain',
  },
  {
    value: 'independent_pharmacy',
    label: 'I operate an independent pharmacy',
  },
];
