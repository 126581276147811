import React from 'react';
import { AppBar, useMediaQuery, useTheme } from '@mui/material';

import { MobileTopNavBar } from './MobileTopNavBar';
import { DesktopTopAppBar } from './DesktopTopAppBar';

export const CustomTopAppBar = ({
  toggleMobileBottomNavDrawer,
  scrolled,
  topnavBarHeight = 60,
}: {
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
  scrolled: boolean; // Info needed for desktop top navbar
  topnavBarHeight?: number; // Info needed for desktop top navbar
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <AppBar
      position={'fixed'}
      component="nav"
      sx={{
        bgcolor: palette.keyops.white.main,
        boxShadow: isMobile ? '0px 1px 2px 0px rgba(0, 0, 0, 0.06)' : 'none',
        height: `${topnavBarHeight}px`,
      }}
      data-testid="top-navbar"
    >
      {isMobile ? (
        <MobileTopNavBar
          toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
        />
      ) : (
        <DesktopTopAppBar scrolled={scrolled} />
      )}
    </AppBar>
  );
};
