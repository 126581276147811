// External Dependencies
import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Control, Controller, get } from 'react-hook-form';

// Internal Dependencies
import { Options } from '../forms/form-constants';

type KeyOpsSingleSelectProps = SelectProps & {
  fieldName: string;
  placeholder: string;
  defaultValue?: string;
  rules?: { [key: string]: string }; // validation rules
  options?: Options;
  control: Control;
  helperText?: string;
  errors: object;
  onChange?: () => void; // optional onChange function
};

const KeyOpsSingleSelect = ({
  fieldName,
  placeholder,
  defaultValue = '',
  rules,
  options = [],
  control,
  helperText,
  errors,
  onChange,
  ...props
}: KeyOpsSingleSelectProps & FormControlProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const error = get(errors, fieldName);
  const renderInput = ({ field }) => (
    <Select
      labelId={'KeyOpsSingleSelect-label'}
      label={placeholder}
      {...field}
      {...props.inputProps}
      sx={{ backgroundColor: isMobile && theme.palette.keyops.white.main }}
      onChange={(e) => {
        field.onChange(e);
        if (onChange) onChange();
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <FormControl size={'small'} error={!!error} fullWidth {...props}>
      <InputLabel id="KeyOpsSingleSelect-label">{placeholder}</InputLabel>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={renderInput}
      />
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
      {error && <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default KeyOpsSingleSelect;
