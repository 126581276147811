// External Dependencies
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material/';
import { styled } from '@mui/system';

// Internal Dependencies
import { axiosCall } from '../../utils/api';
import { mobileBreakPoint } from '../../utils/constants';

const Container = styled('div')`
  width: calc(100% - 40px);
  max-width: 600px;
  padding: 10px;
  margin: 20px auto 20px auto;
  text-align: left;
`;
const BodyText = styled(Typography)`
  margin: 0 0 20px 0;
`;

const Panel = styled('div')`
  width: 100%;
  @media (min-width: ${mobileBreakPoint}) {
    display: inline-block;
    width: 40%;
  }
`;
const VerticalDivider = styled('div')`
  width: 1px;
  height: 120px;
  background: #ccc;
  margin: 20px;
  display: none;
  @media (min-width: ${mobileBreakPoint}) {
    display: inline-block;
  }
`;
const HorizontalDivider = styled('div')`
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 30px 0 30px 0;
  @media (min-width: ${mobileBreakPoint}) {
    display: none;
  }
`;
const KeyOpsButton = styled(Button)`
  border-radius: 25px;
  text-transform: capitalize;
  padding: 5px 25px 5px 25px;
  font-weight: 600;
`;

const ReferralLanding = () => {
  const { t } = useTranslation();

  // this is to support old referral links,
  // new links will have referral id as a search params
  // we should remove this when we remove ROUTES.REFERRAL route.
  const { referralId: urlReferralId } = useParams();

  const [searchParams] = useSearchParams();
  const [title, setTitle] = React.useState('');
  const [payoutValue, setPayoutValue] = React.useState(0);
  const [engagementState, setEngagementState] = React.useState('');
  const [loadingData, setLoadingData] = React.useState(true);

  const referralId = urlReferralId || searchParams.get('referralId');

  const getData = async () => {
    // TODO: I think this endpoint is removed from BE,
    // TODO: we should remove this component as well,
    // TODO: as this component is just here to support old referral links.
    const results = await axiosCall(true, 'get', 'referral', referralId);
    setTitle(results.data.data.title);
    setPayoutValue(results.data.data.payoutValue);
    setEngagementState(results.data.data.engagementState);
    setLoadingData(false);
  };
  useEffect(() => {
    getData();
  }, []);

  if (loadingData) {
    // 5.1 Return load screen if data is not yet loaded
    return (
      <Box align="center" sx={{ pt: 20 }}>
        <CircularProgress size="10rem"></CircularProgress>
      </Box>
    );
  }
  if (engagementState === 'closed') {
    return (
      <Container maxWidth="xl" sx={{ pt: 15, pb: 10 }} sm={{ pt: 15, pb: 10 }}>
        <Typography sx={{ color: '#ccc' }} variant="body1">
          {t('referralLanding.closedTitle')}
        </Typography>
        <Typography sx={{ fontWeight: 700 }} variant="h4">
          {t('referralLanding.closedSubTitle')}
        </Typography>
        <br />

        <Typography variant="body1">
          {t('referralLanding.overwhelmingResponses')}{' '}
          <strong> {t('referralLanding.nowClosed')}</strong>.
        </Typography>
        <br />

        <Panel>
          <Typography variant="body1">
            <strong>{t('referralLanding.notAMember')}</strong>{' '}
            {t('referralLanding.signUpBelow')}
          </Typography>
          <br />
          <KeyOpsButton
            color={'primary'}
            variant="contained"
            href={`/onboarding/${referralId}`}
          >
            {/*TODO restore this once we are on mandrill emails href={`/surveys/referral/${referralId}`}*/}
            {t('general.signUp')}
          </KeyOpsButton>
        </Panel>

        <HorizontalDivider />
        <VerticalDivider />

        <Panel>
          <Typography variant="body1">
            <strong>{t('referralLanding.alreadyAMember')}</strong>{' '}
            {t('referralLanding.logInOption')}
          </Typography>
          <br />
          <KeyOpsButton color={'primary'} variant="outlined" href={`/login`}>
            {t('general.logIn')}
          </KeyOpsButton>
        </Panel>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="xl"
      sx={{ pt: { xs: 10, sm: 15 }, pb: 10 }}
      md={{ pt: 15, pb: 10 }}
    >
      <br />
      <Typography sx={{ fontWeight: 700 }} variant="h4">
        {t('referralLanding.title')}
      </Typography>
      <br />
      <BodyText variant="body1">
        {t('referralLanding.title', { payoutValue, title })}
      </BodyText>
      <KeyOpsButton
        color={'primary'}
        variant="contained"
        href={`/onboarding/?referralId=${referralId}`}
      >
        {t('general.getStarted')}
      </KeyOpsButton>
      <br />
      <br />
      <Link
        style={{ color: '#000' }}
        variant="body1"
        href={`/login/?referralId=${referralId}`}
      >
        {t('referralLanding.link')}
      </Link>
    </Container>
  );
};

export default ReferralLanding;
