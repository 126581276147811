import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SortDirection } from '@tanstack/react-table';
import { Stack, Typography } from '@mui/material';

const ColumnHeader = ({
  headerText,
  sorted,
  direction,
}: {
  headerText: string;
  sorted: SortDirection | false;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
}) => {
  const [hovered, setHovered] = useState(false);
  const showSortIcon = sorted || hovered;
  return (
    <Stack
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      direction={direction ?? 'row'}
      gap={2}
    >
      <Typography fontWeight={500}>{headerText}</Typography>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showSortIcon ? (
          sorted === 'desc' ? (
            <ExpandMoreIcon fontSize="small" aria-label="sorted-descending" />
          ) : (
            <ExpandLessIcon fontSize="small" aria-label="sorted-ascending" />
          )
        ) : (
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ color: 'transparent' }}
            aria-label="info"
          />
        )}
      </span>
    </Stack>
  );
};

export default ColumnHeader;
