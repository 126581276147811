import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EngagementCardFooter } from './EngagementCardFooter';
import i18next from '../../../../../languages/i18n.config';
import {
  Calendar,
  ClockCircle,
  hoverCardStyle,
  KeyopsHeader3,
  KeyopsSubTitle1,
  Plus,
  Rewards,
  RightArrow,
} from '@keyops-hcp/ui-components';

export type EngagementCardProps = {
  type: 'survey' | 'adBoard' | 'referral';
  title: string;
  description?: string;
  duration?: number;
  dates?: JSX.Element;
  honorarium?: number;
  points?: number;
  actionLabel?: string;
  action: () => void;
  icon: JSX.Element;
  iconBgColor: string;
  additionalActions?: JSX.Element;
  accentIcon?: JSX.Element;
};
export const EngagementCard = ({
  type,
  title,
  description,
  duration,
  dates,
  honorarium,
  points,
  actionLabel,
  action,
  icon,
  iconBgColor,
  additionalActions,
  accentIcon,
}: EngagementCardProps) => {
  const { t } = useTranslation();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const styledIcon = React.cloneElement(icon, {
    sx: {
      color: palette.keyops.white.main,
      fontSize: isMobile ? '14px' : '27px',
    },
  });
  const styledAccentIcon = accentIcon
    ? React.cloneElement(accentIcon, {
        sx: {
          color: iconBgColor,
          fontSize: '24px',
        },
      })
    : undefined;

  const footerItems = [];
  const EngagementCardElement = styled(Card)(() => ({
    boxShadow: 'none',
    border: `1px solid ${palette.keyops.blue.lightSteelBlue20}`,
    borderRadius: '8px',
    width: '100%',
    ...hoverCardStyle(),
    '.callToAction': {
      fontSize: '14px',
      color: palette.keyops.blue.keyopsBlue,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      fontWeight: 500,
    },
    '.MuiCardContent-root': {
      padding: isMobile ? '16px 16px 8px 16px' : '16px 24px 16px 24px',
      borderLeft: isMobile ? '8px solid ' + iconBgColor + '33' : undefined,
      '&:last-child': {
        paddingBottom: isMobile ? '8px' : '16px',
      },
    },
    '.textWithIcons': {
      display: 'flex',
      alignItems: 'center',
    },
    '.MuiAvatar-root': {
      backgroundColor: iconBgColor,
      width: isMobile ? '24px' : '46px',
      height: isMobile ? '24px' : '46px',
    },
    '.honosAndPoints': {
      backgroundColor: isMobile ? undefined : palette.keyops.blue.arcticBlue30,
      padding: isMobile ? '8px 8px 0px 8px' : '8px 16px 8px 16px',
      borderRadius: isMobile ? '0px' : '8px',
      color: palette.keyops.blue.keyopsBlue,
      textAlign: isMobile ? 'center' : undefined,
      height: isMobile ? undefined : '88px',
      display: isMobile ? undefined : 'flex',
      alignItems: 'center',
      borderTop: isMobile
        ? '1px solid ' + palette.keyops.blue.midnightBlue + '30'
        : undefined,
    },
  }));

  const typeBadgeStyle: React.CSSProperties = {
    width: '69px',
    backgroundColor: iconBgColor + '33',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (duration) {
    footerItems.push(
      <>
        <ClockCircle
          sx={{
            color: palette.keyops.blue.midnightBlue,
            fontSize: '18px',
          }}
        />
        <span>
          {t('dashboard.activeEngagementsSection.minutes', { duration })}
        </span>
      </>
    );
  }
  if (dates) {
    footerItems.push(
      <>
        <Calendar
          sx={{
            color: palette.keyops.blue.midnightBlue,
            fontSize: '18px',
          }}
        />
        <span>{dates}</span>
      </>
    );
  }
  return (
    <EngagementCardElement onClick={action}>
      <CardContent>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 2 : 3}
        >
          {/* Icon block */}
          {!isMobile && (
            <Box sx={typeBadgeStyle}>
              <Avatar>{styledIcon}</Avatar>
            </Box>
          )}
          {/* Title, description and footer details */}
          <Stack spacing={0.5} flex={1}>
            <Stack direction="row" className="textWithIcons" spacing={1}>
              {isMobile && <Avatar>{styledIcon}</Avatar>}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <KeyopsHeader3>{t('general.' + type)}</KeyopsHeader3>
                {styledAccentIcon}
              </Box>
            </Stack>
            <Typography>{title}</Typography>
            {description && <KeyopsSubTitle1>{description}</KeyopsSubTitle1>}
            {footerItems.length > 0 && (
              <EngagementCardFooter items={footerItems} />
            )}
          </Stack>
          {/* Additional actions */}
          {additionalActions}
          {/* Honos, points and CTA */}
          <Box width={isMobile ? undefined : '163px'}>
            <Stack rowGap={1}>
              {/* Honos and points */}
              {/* TODO: what to show if no points or honos? */}
              {(!!points || !!honorarium) && (
                <Box className="honosAndPoints">
                  <Box>
                    {t('dashboard.activeEngagementsSection.earn')}{' '}
                    {!!honorarium && (
                      <Typography
                        fontSize={isMobile ? '16px' : '18px'}
                        fontWeight={600}
                        component={isMobile ? 'span' : undefined}
                      >
                        {i18next.t('general.dollarAmount', {
                          amount: honorarium,
                        })}
                      </Typography>
                    )}
                    {!!points && (
                      <Typography
                        fontSize="14px"
                        component={isMobile ? 'span' : undefined}
                      >
                        {/* plus only needed if both hono and points */}
                        {!!honorarium && (
                          <Plus
                            sx={{
                              fontSize: isMobile ? '11px' : '14px',
                              px: isMobile ? '4px' : undefined,
                            }}
                          />
                        )}
                        <Rewards
                          sx={{
                            fontSize: '14px',
                            color: palette.keyops.blue.keyopsBlue,
                            px: '2px',
                          }}
                        />
                        <Trans
                          i18n={i18next}
                          i18nKey="dashboard.activeEngagementsSection.pointsAward"
                          values={{ points }}
                          components={{
                            pointsElement: (
                              <Typography
                                component="span"
                                fontSize={isMobile ? '16px' : '18px'}
                                fontWeight={600}
                              />
                            ),
                          }}
                        />
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {!isMobile && actionLabel && (
                <Box className="callToAction">
                  {actionLabel}{' '}
                  <RightArrow sx={{ marginLeft: '8px', fontSize: '18px' }} />
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </EngagementCardElement>
  );
};
