// External Dependencies
import React, { useEffect, useContext, useState } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

// Internal Dependencies
import { UserDto } from '@keyops-hcp/dtos';
import {
  analytics,
  KeyopsHeader1,
  useEarningsByUserId,
} from '@keyops-hcp/ui-components';

import { UserContext } from '../../UserContext';
import { EarningCard } from './components/EarningCard';
import EarningsTable from './components/EarningsTable';
import Loading from '../../components/Loading';
import {
  cleanEarningsData,
  getTotalEarnings,
} from '../../utils/functions/earning-utils';
import i18next from '../../languages/i18n.config';

export const Earnings = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.only('xs'));

  // Get User data with corresponding Engagement data
  const context = useContext(UserContext);

  const [userData, setUserData] = useState<UserDto>({} as UserDto);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    context.getData({
      setUserData: setUserData,
      setLoadingData: setLoadingData,
    });
  }, []);

  useEffect(() => {
    if (!loadingData) {
      analytics.track('Earnings');
    }
  }, [loadingData]);

  const { data: earningsData, isLoading: loadingEarnings } =
    useEarningsByUserId(userData?.id);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        spacing={{ xs: 2 }}
      >
        <Box>
          <KeyopsHeader1>{i18next.t('earnings.title')}</KeyopsHeader1>
          <Typography variant="body2">
            {i18next.t('earnings.subtitle')}
          </Typography>
        </Box>
        <Stack
          direction={{ xs: 'row-reverse', sm: 'column' }}
          spacing={{ xs: 2, sm: 0 }}
          alignItems={{ xs: 'center', sm: 'flex-end' }}
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          bgcolor={{ xs: palette.keyops.white.main }}
          px={{ xs: 2 }}
          py={{ xs: 0.5 }}
          borderRadius={1}
        >
          <Box>
            {loadingData || loadingEarnings ? (
              <Loading size={isMobile ? '16px' : '24px'} pt={0} />
            ) : (
              <Typography
                variant={isMobile ? 'h6' : 'h5'}
                component={'h1'}
                color={palette.keyops.blue.midnightBlue}
                fontWeight={600}
              >
                $
                {Intl.NumberFormat('currency').format(
                  getTotalEarnings(earningsData)
                )}
              </Typography>
            )}
          </Box>
          <Typography
            color={palette.keyops.gray[600]}
            fontWeight={500}
            textAlign={'right'}
          >
            {i18next.t('earnings.amountEarned')}
          </Typography>
        </Stack>
      </Stack>
      {/* mobile view */}
      <Box display={{ sm: 'none' }} mt={3}>
        {earningsData && cleanEarningsData(earningsData)?.length === 0 && (
          <Typography
            variant={'body1'}
            color={palette.keyops.gray[500]}
            textAlign={'center'}
          >
            {i18next.t('earnings.earningsTableNoData')}
          </Typography>
        )}
        {earningsData &&
          cleanEarningsData(earningsData)?.map((earningEngagement, index) => {
            return (
              <EarningCard key={`${index}`} engagement={earningEngagement} />
            );
          })}
      </Box>
      {/* desktop view */}
      {earningsData && (
        <Box display={{ xs: 'none', sm: 'block' }} mt={3}>
          <EarningsTable
            loadingEarnings={loadingEarnings}
            earningsData={cleanEarningsData(earningsData)}
          />
        </Box>
      )}
    </>
  );
};
