// External Dependencies
import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Control, Controller, get, useWatch } from 'react-hook-form';

// Internal Dependencies
import { Options } from '../forms/form-constants';

type KeyOpsMultiSelectProps = {
  fieldName: string;
  control: Control;
  defaultValue?: string;
  rules?: { [key: string]: string }; // validation rules
  placeholder: string;
  options?: Options;
  errors: object;
  limit?: number;
  onChange?: () => void; // optional onChange function
};

const KeyOpsMultiSelect = ({
  fieldName,
  control,
  defaultValue = '',
  rules,
  placeholder,
  options = [],
  errors,
  limit,
  onChange,
  ...props
}: KeyOpsMultiSelectProps & FormControlProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedOptions =
    useWatch({
      control,
      name: fieldName,
    }) || [];
  const error = get(errors, fieldName);

  // mapping selected values with labels and
  // joining them with comma to display multiple values on screen
  const renderSelectedValue = (selected: string[]) =>
    selected
      .map((value) => options.find((opt) => opt.value === value)?.label || '')
      .join(', ');

  const renderInput = ({ field }) => (
    <Select
      labelId={'KeyOpsMultiSelect-label'}
      label={placeholder}
      renderValue={renderSelectedValue}
      {...field}
      multiple
      sx={{ backgroundColor: isMobile && theme.palette.keyops.white.main }}
      onChange={(e) => {
        field.onChange(e);
        if (onChange) onChange();
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={
            selectedOptions.length >= (limit || Infinity) &&
            !selectedOptions.includes(option.value)
          }
        >
          <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <FormControl size={'small'} error={!!error} fullWidth {...props}>
      <InputLabel id="KeyOpsMultiSelect-label">{placeholder}</InputLabel>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={(defaultValue && defaultValue.split(',')) || []}
        rules={rules}
        render={renderInput}
      />
      {error && <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default KeyOpsMultiSelect;
