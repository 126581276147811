import React, { useEffect } from 'react';

import { useSurveyContext } from '../../../higher-order-components/AppLayout/SurveyContext';
import { Box } from '@mui/material';

export const SurveyIframe = ({ ssLink }: { ssLink: string }) => {
  const { setSurveyIframeRendered } = useSurveyContext();

  useEffect(() => {
    setSurveyIframeRendered(true);

    return () => {
      setSurveyIframeRendered(false);
    }; // cleanup function to reset the state when the component is unmounted
  });

  const iframe = (
    <iframe
      title={'survey'}
      style={{ border: 'none' }}
      src={ssLink}
      width="100%"
      height="100%"
    ></iframe>
  );
  return (
    <Box
      position="absolute"
      top={{ md: '80px', sm: '56px', xs: '56px' }}
      left={{ md: '56px', sm: '8px', xs: '8px' }}
      right={{ md: '56px', sm: '8px', xs: '8px' }}
      // If I set bottom to 0 we get vertical scrolling
      bottom="8px"
    >
      {iframe}
    </Box>
  );
};
