// External Dependencies
import React, { createRef, forwardRef, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Link,
  Typography,
  Grid,
  Alert,
  AlertTitle,
  Box,
  Divider,
} from '@mui/material';
import InputUnstyled from '@mui/base/InputUnstyled';

// Internal Dependencies
import { useAuth } from '../../higher-order-components/useAuth';
import { StyledInputRoot, StyledInputElement, Outline } from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import PasswordInput from '../../components/PasswordInput';
import { DASHBOARD } from '../../utils/routes';
import GoogleButton from '../../components/GoogleButton';
import ToastContext from '../../components/Toast/ToastContext';

const CustomInput = forwardRef(function CustomInput(props, ref) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

CustomInput.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
    Textarea: PropTypes.elementType,
  }),
};

const SetupPassword = () => {
  const { t } = useTranslation();

  const { setupPasswordAndLogin, setupPasswordAndLoginWithGoogle } = useAuth();
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const googleButtonRef = createRef(null);
  const { triggerToast } = useContext(ToastContext);

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    hidden: true,
  });

  const handleChange = async (event, field) => {
    await setValues({ ...values, [field]: event.target.value });

    if (event.keyCode === 13) {
      setupPassword();
    }
  };

  const setupPassword = async () => {
    const { password, confirmPassword } = values;

    if (password !== confirmPassword || password.trim() === '') {
      setValues({
        ...values,
        errorText: t('setupPassword.matchError'),
        hidden: false,
      });
      return;
    }
    try {
      const result = await setupPasswordAndLogin(resetToken, password);

      if (result?.status === 200) {
        console.log('navigating to dashboard.');
        navigate(DASHBOARD);
      } else {
        const type = 'error';
        const message =
          result?.response?.status === 401
            ? t('setupPassword.outOfDateLinkText')
            : t('general.errorText');

        triggerToast({
          type: type,
          message: message,
        });
      }
      navigate(DASHBOARD);
    } catch (e) {
      console.log(e);
      setValues({
        ...values,
        errorText: t('setupPassword.error'),
        hidden: false,
      });
    }
  };

  const handleSetupPasswordWithGoogle = async (responseJWT) => {
    try {
      const result = setupPasswordAndLoginWithGoogle(responseJWT);

      if (result?.status === 200) {
        navigate(DASHBOARD);
      } else {
        const type = 'error';
        const message =
          result?.response?.status === 401
            ? t('setupPassword.noAccount')
            : t('general.errorText');
        triggerToast({
          type: type,
          message: message,
        });
      }
    } catch (e) {
      console.log(e);
      setValues({
        ...values,
        errorText: t('setupPassword.error'),
        hidden: false,
      });
    }
  };

  return (
    <Outline>
      <Grid container align="center">
        <Grid item xs={12}>
          <img style={{ height: 60 }} src={Logo} alt="KeyOps Logo" />
        </Grid>
        <Grid item xs={12} sx={{ p: 2.1 }}>
          <Typography>{t('setupPassword.instructions')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }} hidden={values.hidden}>
          <Box textAlign={'left'} maxWidth={'400px'}>
            <Alert severity="warning">
              <AlertTitle>{t('setupPassword.warningText')}</AlertTitle>
              <strong>{values.errorText}</strong>
            </Alert>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box textAlign={'center'} maxWidth={'400px'}>
            <PasswordInput
              id="setup-password-password"
              onChange={(e) => {
                handleChange(e, 'password');
              }}
            />
            <PasswordInput
              id="setup-password-confirm-password"
              placeholder="Confirm Password"
              onChange={(e) => {
                handleChange(e, 'confirmPassword');
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2, pb: 3 }}>
          <RoundedButton variant="contained" fullWidth onClick={setupPassword}>
            {t('setupPassword.savePassword')}
          </RoundedButton>
        </Grid>
        <Divider sx={{ height: '100%', width: '100%' }}>
          <Typography variant="caption">{t('general.or')}</Typography>
        </Divider>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <GoogleButton
            ref={googleButtonRef}
            callbackFunc={handleSetupPasswordWithGoogle}
            text="continue_with"
          />
        </Grid>
        <Grid item xs={12} align="center" sx={{ mt: 5 }}>
          <Typography>
            <Link href="/login">{t('setupPassword.haveAnAccount')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Outline>
  );
};

export default SetupPassword;
