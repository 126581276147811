// External Dependencies
import React from 'react';
import { useTheme, useMediaQuery, Stack, IconButton } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { EngagementCard } from './EngagementCard';
import useReferralShareOptions from '../../../../../custom-hooks/useReferralShareOptions';
import Loading from '../../../../../components/Loading';
import { FilledSend, Mail, Link, Share } from '@keyops-hcp/ui-components';

type ReferralOpportunitiesCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  points?: number;
};

const ReferralOpportunitiesCard = ({
  engagementId,
  title,
  payout,
  points,
}: ReferralOpportunitiesCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const { isLoading, isError, handleCopyClick, mailToClick, mobileShare } =
    useReferralShareOptions(engagementId);

  const additionalActions = isMobile ? (
    <></>
  ) : isLoading ? (
    <Loading />
  ) : (
    <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
      <IconButton
        data-testid="copy-link-btn"
        onClick={handleCopyClick}
        disabled={isError}
      >
        <Link
          sx={{
            fontSize: '28px',
            color: theme.palette.keyops.blue.keyopsBlue,
          }}
        />
      </IconButton>
      <IconButton
        data-testid="mail-btn"
        onClick={mailToClick}
        disabled={isError}
      >
        <Mail
          sx={{
            fontSize: '32px',
            color: theme.palette.keyops.blue.keyopsBlue,
          }}
        />
      </IconButton>
    </Stack>
  );

  return (
    <>
      <EngagementCard
        action={() => isMobile && mobileShare()}
        actionLabel={isMobile && t('general.share')}
        honorarium={payout}
        title={title}
        icon={<FilledSend />}
        iconBgColor={theme.palette.keyops.blue.midnightBlue}
        type="referral"
        points={points}
        accentIcon={isMobile && <Share />}
        additionalActions={additionalActions}
      />
    </>
  );
};

export default ReferralOpportunitiesCard;
