type optionsType = {
  value: string;
  label: string;
};

export const PHARMACIST_TYPES: Array<optionsType> = [
  {
    value: 'pharmacy_owner',
    label: 'Pharmacy owner',
  },
  {
    value: 'full_time_pharmacist',
    label: 'Full-time pharmacist',
  },
  {
    value: 'part_time_pharmacist',
    label: 'Part-time pharmacist',
  },
];
