import { FormInputs } from '../../pages/Onboarding/forms/form-constants';
import { i18n } from 'i18next';

/**
 * Helper function to get specialty and subspecialty structured from specialtyFields
 * @param specialtyFields specialtyFields
 */
export const structureSpecialtiesData = (
  specialtyFields: { specialty: string; subSpecialty?: string[] }[]
): { specialty: string[]; subSpecialty: string[] } => {
  const specialty = specialtyFields?.map(
    (specialtyField: { specialty: string }) => specialtyField.specialty
  );
  const subSpecialty = specialtyFields
    ?.flatMap(
      (specialtyField: { subSpecialty?: string[] }) =>
        specialtyField.subSpecialty
    )
    .filter(
      (subSpecialties: string) => subSpecialties && subSpecialties.length > 0
    );
  return { specialty, subSpecialty };
};

export const onboardingPostProcessing = (data: FormInputs, i18n: i18n) => {
  // Add the language preference to the DTO
  data['languagePreference'] = i18n.language;

  // Populate year of birth from date of birth
  data['yearOfBirth'] = new Date(data.dateOfBirth).getFullYear();

  // Add physician specialty/subSpecialty from specialtyFields
  if (data.hcpType === 'physician') {
    const { specialty, subSpecialty } = structureSpecialtiesData(
      data['physician']['specialtyFields']
    );
    data['physician']['specialty'] = specialty;
    data['physician']['subSpecialty'] = subSpecialty;
  }

  // Format nurse practiceSettingDetail
  if (data.hcpType === 'nurse' && data.country === 'US') {
    data['nurse']['practiceSettingDetail'] = data['nurse']['hospitalSetting'];
    if (data['nurse']['practiceSettingDetail'] === '') {
      delete data['nurse']['practiceSettingDetail'];
    }
  }

  // nested data cleanup step
  data['hcpProfile'] = data[data.hcpType];
  delete data['physician'];
  delete data['pharmacist'];
  delete data['nurse'];

  return data;
};
