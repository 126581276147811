import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

import ActiveEngagementsSection from './components/ActiveEngagementsSection';
import { KeyopsHeader2 } from '@keyops-hcp/ui-components';
import {
  getActiveAdBoardInvitations,
  getActiveInvitations,
  getCompletedInvitationsWithReferrals,
} from '../../../../utils/functions/invitation-utils';

type EngagementSectionProps = {
  userData: UserDto;
  engagementData: EngagementData;
};

const EngagementSection = ({
  userData,
  engagementData,
}: EngagementSectionProps) => {
  const { t } = useTranslation();

  // Functions
  const inviteCount = useMemo(
    () =>
      getActiveAdBoardInvitations(engagementData.adBoardInvitations).length +
      getActiveInvitations(engagementData.surveyInvitations).length +
      getCompletedInvitationsWithReferrals(engagementData.surveyInvitations)
        .length,
    [engagementData]
  );

  return (
    <Box>
      <Box my={{ md: 3, sm: 2, xs: 1 }}>
        <KeyopsHeader2>
          {t('dashboard.activeEngagementsSection.title')}
        </KeyopsHeader2>
        {!!inviteCount && (
          <Typography variant="body2" mt={1}>
            {t('dashboard.activeEngagementsSection.opportunitiesSummary', {
              count: inviteCount,
            })}
          </Typography>
        )}
      </Box>
      {/* Active Engagements Section */}
      <ActiveEngagementsSection
        userData={userData}
        engagementData={engagementData}
      />
    </Box>
  );
};

export default EngagementSection;
