import React from 'react';

import { useAuth } from '../../higher-order-components/useAuth';
import NotFoundVisual from './components/NotFoundVisual';
import { Route, Routes } from 'react-router';
import AppLayout from '../../higher-order-components/AppLayout';
import { NOT_FOUND } from '../../utils/routes';
import UnAuthedNotFoundVisual from './components/UnAuthedNotFoundVisual';

const NotFound = () => {
  const { authed } = useAuth();

  return (
    <Routes>
      {authed ? (
        <Route element={<AppLayout />}>
          <Route path={NOT_FOUND} element={<NotFoundVisual />} />
        </Route>
      ) : (
        <Route path={NOT_FOUND} element={<UnAuthedNotFoundVisual />} />
      )}
    </Routes>
  );
};

export default NotFound;
