import React from 'react';
import { HCPAdBoardDataProvider } from '@keyops-hcp/ui-components';

import AdBoardLayoutWrapper from './AdBoardLayoutWrapper';

const AdBoardLayout = () => {
  return (
    <HCPAdBoardDataProvider>
      <AdBoardLayoutWrapper />
    </HCPAdBoardDataProvider>
  );
};

export default AdBoardLayout;
