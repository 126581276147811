import React, { useCallback, useRef, useState } from 'react';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import { CustomTopAppBar } from './components/CustomTopAppBar';
import { CustomSideNavBar } from './components/CustomSideNavBar';

const AppLayout = (): JSX.Element => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // Different behaviour depending on url
  const location = useLocation();
  const isSurveyRoute = location.pathname.startsWith('/engagement/');

  // Handling display of side navbar items in mobile
  const [openMobileBottomNavDrawer, setOpenMobileBottomNavDrawer] =
    useState(false);
  const toggleMobileBottomNavDrawer = useCallback(
    (newOpen: boolean) => setOpenMobileBottomNavDrawer(newOpen),
    []
  );

  // Desktop top navbar handling: on scroll, display a border bottom
  const [scrolled, setScrolled] = useState(false);
  const topnavBarHeight = 60;
  const scrollDemoRef = useRef(null);

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current;
      if (scrollTop > topnavBarHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  return (
    <Box id="app-layout-box" data-testid="app-layout-box" overflow={'hidden'}>
      <Box
        display="flex"
        height={`100dvh`}
        bgcolor={palette.keyops.white.main}
        overflow={'hidden'}
      >
        <CustomSideNavBar
          openMobileBottomNavDrawer={openMobileBottomNavDrawer}
          toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
        />

        <Box
          component="main"
          data-testid="main-content"
          flexGrow={1}
          bgcolor={palette.keyops.white.main}
          overflow={'scroll'}
          mb={isMobile && topnavBarHeight + 'px'}
          px={!isSurveyRoute && { xs: 2.5, sm: 6, md: 8 }}
          py={!isSurveyRoute && 3}
          sx={{
            transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          }}
          ref={scrollDemoRef}
          onScroll={handleScroll}
        >
          <CustomTopAppBar
            toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
            topnavBarHeight={topnavBarHeight}
            scrolled={scrolled}
          />
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
