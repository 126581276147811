// External Dependencies
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

// TODO: rm and replace all occurrence by the Loader component in ui-component
const Loading = ({
  align,
  size,
  pt,
}: {
  align?: string;
  size?: string;
  pt?: number;
}) => {
  return (
    <Box
      display="flex"
      alignItems={align ?? 'center'}
      justifyContent="center"
      sx={{ pt: pt ?? 20 }}
    >
      <CircularProgress aria-label="progress" size={size ?? '10rem'} />
    </Box>
  );
};

export default Loading;
