import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { FiCheckCircle } from 'react-icons/fi';

import i18next from '../../../../languages/i18n.config';
import { CallToActionBox } from '../CallToActionBox';

export const AdBoardUpcomingBox = ({ startDate }: { startDate: string }) => {
  const { t } = i18next;
  const theme = useTheme();

  const startDateDisplay = startDate
    ? new Date(startDate).toLocaleDateString(i18next.language, {
        month: 'short',
        day: 'numeric',
      })
    : undefined;
  return (
    <CallToActionBox
      icon={
        <FiCheckCircle
          style={{
            color: theme.palette.keyops.teal,
            fontSize: '70px',
            flexShrink: 0,
          }}
        />
      }
      title={t('ad_board.landing_page.cta.upcoming.title')}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          color: theme.palette.keyops.gray[700],
        }}
      >
        {startDateDisplay
          ? t('ad_board.landing_page.cta.upcoming.copy', {
              startDate: startDateDisplay,
            })
          : t('ad_board.landing_page.cta.upcoming.copy_no_date')}
      </Typography>
    </CallToActionBox>
  );
};
