import React from 'react';
import { ModeratorUserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

import { ProfileField } from './ProfileField';

export const ModeratorProfileFields = ({
  userData,
}: {
  userData: ModeratorUserDto;
}) => {
  const { t } = useTranslation();

  const moderatorProfile = userData.hcpProfile;

  return (
    <>
      <ProfileField
        title={t('profile.profileDetails.memberSince')}
        content={new Date(userData.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      />
      <ProfileField
        title={t('profile.profileDetails.companyName')}
        content={<>{moderatorProfile.companyName}</>}
      />
      <ProfileField
        title={t('profile.profileDetails.titleField')}
        content={<>{moderatorProfile.jobTitle}</>}
      />
    </>
  );
};
