import React, { useState } from 'react';
import ToastContext from './ToastContext';

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastDuration, setToastDuration] = useState(3000);

  const triggerToast = ({ type, message, duration }) => {
    setToastType(type);
    setToastMessage(message);
    setShowToast(true);
    if (duration) setToastDuration(duration);
  };
  const clearToast = () => setShowToast(false);

  return (
    <ToastContext.Provider
      value={{
        showToast,
        toastType,
        toastMessage,
        toastDuration,
        triggerToast,
        clearToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
