// External Dependencies
import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import { Path, UseFormRegister, get } from 'react-hook-form';

// Internal Dependencies
import { FormInputs } from '../forms/form-constants';

type ruleValidationType = {
  [key: string]: (() => Promise<boolean>) | string | RegExp;
};

type KeyOpsTextFieldProps = TextFieldProps & {
  fieldName: Path<FormInputs>;
  placeholder: string;
  type?: string;
  rules?: { [key: string]: string | ruleValidationType };
  disabled?: boolean;
  register: UseFormRegister<FormInputs>;
  helperText?: string;
  errors: object;
};

const KeyOpsTextField = ({
  fieldName,
  placeholder,
  type,
  rules,
  disabled,
  register,
  helperText,
  errors,
  ...props
}: KeyOpsTextFieldProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const error = get(errors, fieldName);

  return (
    <Box width={'100%'}>
      {/* TODO: I think we should convert this component to use Controller component as well */}
      <TextField
        fullWidth
        size={'small'}
        type={type}
        label={placeholder}
        disabled={disabled}
        {...register(fieldName, rules)}
        error={!!error}
        sx={{ backgroundColor: isMobile && theme.palette.keyops.white.main }}
        {...props}
      />
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
      {error && (
        <FormHelperText error sx={{ ml: 0 }}>
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default KeyOpsTextField;
