// External Dependencies
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Link,
  Button,
  useTheme,
  MenuItem,
  Select,
  Stack,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material/';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import RewardCard from './RewardCard';
import { RewardTierNames } from '../../../data/';

const ClaimRewardOptions = ({
  tier,
  rewards,
  claimReward,
  setShowCustomReward,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const boxRef = useRef(null);
  const [rewardCards, setRewardsCards] = useState([]);
  const [category, setCategory] = useState('');
  const [selectedCard, setSelectedCard] = useState('');

  const loadImageAndBuildCard = async () => {
    const loadedImages = await Promise.all(
      rewards
        .filter(
          (reward) =>
            reward.category === category || tier === RewardTierNames.silver
        )
        .map(async (reward, index) => {
          const imageUrl = await import(
            `../../../logos/${reward.logoFileName}`
          ); // Use template literals for dynamic import

          return (
            <RewardCard
              key={`RewardCard-${index}`}
              tier={tier}
              rewardName={reward.name}
              rewardDescription={reward.description}
              selected={reward.name === selectedCard}
              setSelectedCard={setSelectedCard}
              imageUrl={imageUrl.default}
            />
          );
        })
    );
    setRewardsCards(loadedImages);
  };

  useEffect(() => {
    // pre select the first category
    setCategory(rewards[0].category);
  }, []);

  useEffect(() => {
    setSelectedCard('');
    loadImageAndBuildCard();
  }, [category]);

  useEffect(() => {
    loadImageAndBuildCard();
  }, [selectedCard]);

  return (
    <Box ref={boxRef}>
      <Typography
        data-testid="claim-reward-options"
        aria-label="share"
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          color: theme.palette.keyops.gray[700],
        }}
      >
        {t('rewards.claimReward.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          color: theme.palette.keyops.gray[700],
          pt: '16px',
          pb: { xs: '8px', sm: '24px' },
        }}
      >
        {t(`rewards.claimReward.rewardDescription.${tier}`)}
      </Typography>
      {tier !== RewardTierNames.silver && (
        <Box
          sx={{
            pt: '16px',
            pb: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormControl fullWidth sx={{ width: { xs: '100%', sm: '320px' } }}>
            <InputLabel>{t('rewards.claimReward.select')}</InputLabel>
            <Select
              label={t('rewards.claimReward.select')}
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {Array.from(new Set(rewards.map((obj) => obj.category))).map(
                (value: string, index: number) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
      )}
      <Stack
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: { xs: '8px', sm: '16px' },
        }}
      >
        {rewardCards}
      </Stack>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'end' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          mt: '16px',
        }}
      >
        <Typography>
          <Link
            onClick={() => {
              setShowCustomReward(true);
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: theme.palette.keyops.blue.keyopsBlue,
            }}
          >
            {tier === RewardTierNames.platinum &&
              category !== 'Rides' &&
              t('rewards.claimReward.platinumAlternative')}
          </Link>
        </Typography>
        <Button
          sx={{
            width: '76px',
            textTransform: 'capitalize',
            fontSize: '16px',
            mt: {
              xs: tier === RewardTierNames.platinum ? '16px' : 'none',
              sm: '16px',
            },
          }}
          variant="contained"
          disableElevation
          disabled={selectedCard === ''}
          onClick={() => {
            claimReward(
              selectedCard,
              boxRef.current.getBoundingClientRect().top - 24, // offset 24px to make up for padding in basicModal
              boxRef.current.offsetHeight // set current modal height for transition
            );
          }}
        >
          {t('rewards.claimReward.claimButton')}
        </Button>
      </Stack>
    </Box>
  );
};

export default ClaimRewardOptions;
