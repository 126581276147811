import { Button } from '@mui/material';
import React from 'react';
import { theme } from '@keyops-hcp/ui-components';
export const CtaButton = ({
  label,
  action,
  disabled = false,
  endIcon = undefined,
}) => {
  const { palette } = theme;
  return (
    <Button
      disabled={disabled}
      aria-label={label}
      variant="contained"
      endIcon={endIcon}
      sx={{
        borderRadius: 2,
        fontSize: { md: '16px', sm: '12px', xs: '12px' },
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: palette.keyops.blue.keyopsBlue,
        paddingY: 1,
        paddingX: 2,
        display: 'inline-flex',
        height: { md: '40px', sm: '32px', xs: '32px' },
      }}
      disableElevation
      onClick={action}
    >
      {label}
    </Button>
  );
};
