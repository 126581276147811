import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

export const ProfileField = ({ title, content }) => {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Typography
        color={theme.palette.keyops.black.main}
        fontSize={14}
        fontWeight={500}
      >
        {title}
      </Typography>
      <Typography
        color={theme.palette.keyops.gray[600]}
        fontSize={14}
        mt={0.75}
      >
        {content}
      </Typography>
    </Grid>
  );
};
