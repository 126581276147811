import React from 'react';
import { Stack } from '@mui/material';

import { BottomNavBarDrawer } from '@keyops-hcp/ui-components';

import { useNavConstants } from '../../../custom-hooks/useNavConstants';
import { CustomNavItem } from './CustomNavItem';

export const MobileBottomNavDrawer = ({
  openMobileBottomNavDrawer,
  toggleMobileBottomNavDrawer,
}: {
  openMobileBottomNavDrawer: boolean;
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
}) => {
  const { bottomNavItems } = useNavConstants();

  return (
    <BottomNavBarDrawer
      contentType="bottomNavItems"
      isOpen={openMobileBottomNavDrawer}
      onOpen={() => toggleMobileBottomNavDrawer(true)}
      onClose={() => toggleMobileBottomNavDrawer(false)}
    >
      <Stack direction={'column'} justifyContent={'space-between'} flexGrow={1}>
        {bottomNavItems.map((navItem) => (
          <CustomNavItem
            key={navItem.name}
            item={navItem}
            isMobileBottomNavbar={false}
            isMobileBottomDrawer={true}
          />
        ))}
      </Stack>
    </BottomNavBarDrawer>
  );
};
