import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import ContactDrawer from '../../components/ContactDrawer';
import ContactModal from '../../components/ContactModal';

const ContactComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div data-testid="ContactComponent">
      {!isMobile && <ContactModal />}
      {isMobile && <ContactDrawer />}
    </div>
  );
};

export default ContactComponent;
