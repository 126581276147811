// External dependencies
import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Stack } from '@mui/material/';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import './index.css';
import BackButton from '../../components/BackButton';
import { axiosCall } from '../../utils/api';
import { UserContext } from '../../UserContext';
import Vimeo from '@u-wave/react-vimeo';
import Loading from '../../components/Loading';
import { PreEngagementPage } from './components/PreEngagementPage';
import { DASHBOARD, ENGAGEMENT_DISPLAY_ROOT } from '../../utils/routes';
import SurveyDone from '../SurveyDone';
import { analytics, KeyopsHeader1 } from '@keyops-hcp/ui-components';
import { CtaButton } from '../../components/CtaButton';
import {
  EngagementDto,
  InvitationDto,
  isHcpUser,
  UserDto,
} from '@keyops-hcp/dtos';
import ToastContext from '../../components/Toast/ToastContext';
import { TOAST_TYPES } from '../../utils/constants';
import EngagementNotFound from './components/EngagementNotFound';

const Engagement = () => {
  // 1. Setup State/Data
  const { t } = useTranslation();

  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { engagementId } = useParams();
  const [engagementData, setEngagementData] = React.useState(
    {} as EngagementDto
  );
  const [invitationData, setInvitationData] = React.useState(
    {} as InvitationDto
  );
  const [userData, setUserData] = React.useState({} as UserDto);
  const [loadingData, setLoadingData] = React.useState(true);
  const [showMedia, setShowMedia] = React.useState(false);
  //only used for video engagements
  const [canContinue, setCanContinue] = React.useState(false);
  const { triggerToast } = useContext(ToastContext);

  //2. Setup needed functions
  const startPreEngagement = () => {
    //show the pre engagement if there is one, otherwise show the survey proper
    if (engagementType === 'video' || engagementType === 'attachment') {
      setShowMedia(true);
    } else {
      startEngagement();
    }
  };
  const declineEngagement = () => {
    axiosCall(
      false,
      'post',
      'engagement',
      engagementId,
      {},
      { state: 'ineligible' }
    );
    invitationData.state = 'ineligible';
    triggerToast({
      type: TOAST_TYPES.SUCCESS,
      duration: 6000, //ten seconds
      message: t('engagement.eligibilityConfirmation.declineToastMessage'),
    });
    analytics.track('Decline Engagement', {
      title: engagementData.title,
      id: engagementData.id,
    });

    navigate(DASHBOARD);
  };

  const startEngagement = () => {
    navigate(`${ENGAGEMENT_DISPLAY_ROOT}/${engagementId}`);
    return;
  };

  const { state: invitationState } = invitationData;
  // 3. Load data
  useEffect(() => {
    context.getInvitationData({
      setUserData: setUserData,
      setEngagementData: setEngagementData,
      setInvitationData: setInvitationData,
      setLoadingData: setLoadingData,
      engagementId: engagementId,
    });
  }, []);

  if (!isHcpUser(userData)) {
    navigate('/');
    return;
  }

  // 4. Loader by Default
  if (loadingData) {
    // Return load screen if data is not yet loaded
    return <Loading />;
  }

  // 5. Return error page if user is not invited OR if url has been tampered with OR if engagement is not published
  if (
    !engagementData ||
    parseInt(engagementId).toString() !== engagementId ||
    invitationData.engagement.state !== 'published' ||
    invitationState === 'ineligible'
  ) {
    return <EngagementNotFound showBackButton={false} />;
  }

  const {
    summary,
    type: engagementType,
    mediaDescription,
    mediaLink,
    resultsHTML,
    title,
    payoutValue,
    estimatedTime,
    attachmentLink,
    hasOtherEligibilityCriteria,
    otherEligibilityCriteria,
  } = engagementData;

  if (invitationState === 'completed' && resultsHTML) {
    analytics.track('Engagement Results', {
      title: engagementData.title,
      id: engagementData.id,
    });
    // if the invite is completed and we have result, show them.
    return (
      <>
        <iframe
          title={'results'}
          style={{ border: 'none' }}
          srcDoc={resultsHTML}
          width="100%"
          height={window.innerHeight - 20}
        ></iframe>
        <BackButton />
      </>
    );
  }

  if (invitationState !== 'new' && invitationState !== 'in_progress') {
    analytics.track('Engagement Done', {
      title: engagementData.title,
      id: engagementData.id,
    });
    // if the invitation state is not new, show referral / post engagement page
    // TODO show 404 page instead since the user shouldn't be able to access this page anymore
    return <SurveyDone />;
  }

  if (!showMedia) {
    analytics.track('Engagement Landing', {
      title: engagementData.title,
      id: engagementData.id,
    });
    //Initial state of engagement view, we're just showing the summary
    return (
      <PreEngagementPage
        title={title}
        payoutValue={payoutValue}
        summary={summary}
        estimatedTime={estimatedTime}
        state={invitationState}
        hasOtherEligibilityCriteria={hasOtherEligibilityCriteria}
        otherEligibilityCriteria={otherEligibilityCriteria}
        startEngagement={startPreEngagement}
        declineEngagement={declineEngagement}
      />
    );
  }

  // past here, showMedia must be true
  // if it's an attachment engagement and the attachment is in webflow, webflow prevents framing, so we'll open as a new tab and flip to survey
  if (engagementType === 'attachment') {
    if (attachmentLink.includes('webflow.io')) {
      analytics.track('Engagement Attachment Punchout', {
        title: engagementData.title,
        id: engagementData.id,
      });
      window.open(attachmentLink, '_blank', 'noreferrer');
      startEngagement();
    } else {
      //otherwise, show the url in a iframe
      analytics.track('Engagement Attachment Embedded', {
        title: engagementData.title,
        id: engagementData.id,
      });

      return (
        <Box flexGrow={1} overflow={'auto'} px={{ xs: 2, sm: 6, md: 8 }} py={3}>
          <Typography variant="body1">{mediaDescription}</Typography>
          <iframe
            title={'vimeo video'}
            style={{ border: 'none' }}
            src={attachmentLink}
            width="100%"
            height={`${window.innerHeight - 180}px`}
          ></iframe>
          <Grid item xs={12} alignItems="center">
            <CtaButton
              endIcon={<ArrowForward />}
              label={t('engagement.continueButtonLabel')}
              action={() => startEngagement()}
            />
          </Grid>
          <BackButton />
        </Box>
      );
    }
  }

  analytics.track('Engagement Video', {
    title: engagementData.title,
    id: engagementData.id,
  });
  return (
    <Stack
      gap={2}
      flexGrow={1}
      overflow={'auto'}
      px={{ xs: 2, sm: 6, md: 8 }}
      py={3}
    >
      <KeyopsHeader1>{title}</KeyopsHeader1>
      {!!mediaDescription && (
        <Typography variant="body1">
          {mediaDescription.replace('<p>', '').replace('</p>', '')}
        </Typography>
      )}
      <Vimeo
        video={mediaLink}
        className="custom-vimeo"
        onEnd={async () => {
          setCanContinue(true);
        }}
      />

      <CtaButton
        disabled={!canContinue}
        endIcon={<ArrowForward />}
        label={t('engagement.continueButtonLabel')}
        action={() => startEngagement()}
      />
    </Stack>
  );
};

export default Engagement;
