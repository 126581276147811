// External Dependencies
import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Control, Controller, get, useWatch } from 'react-hook-form';

// Internal Dependencies
import { Options } from '../forms/form-constants';

type KeyOpsOptionMultiSelectProps = {
  fieldName: string;
  placeholder: string;
  defaultValue?: string[];
  rules?: { [key: string]: string }; // validation rules
  options?: Options;
  control: Control;
  helperText?: string;
  setValue?: (name: string, value: unknown, config?: object) => void;
  errors: object;
};

const KeyOpsOptionMultiSelect = ({
  fieldName,
  placeholder,
  defaultValue = [],
  rules,
  options = [],
  control,
  helperText,
  setValue,
  errors,
}: KeyOpsOptionMultiSelectProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedOptions =
    useWatch({
      control,
      name: fieldName,
    }) || [];
  const error = get(errors, fieldName);
  const handleOptionClick = (event) => {
    const newValue = event.target.value;
    if (selectedOptions?.includes(newValue)) {
      // If the option is already selected, remove it from the selectedOptions array
      setValue(
        fieldName,
        selectedOptions.filter((item) => item !== newValue)
      );
    } else {
      // If the option is not selected, add it to the selectedOptions array
      setValue(fieldName, [...selectedOptions, newValue]);
    }
  };

  const renderInput = ({ field }) => {
    const selectedFieldValues = field.value;
    return (
      <Grid container spacing={1} mt={2}>
        {options.map((option, i) => (
          <Grid key={`${option.value}-${i}`} item xs={6} zeroMinWidth>
            <Button
              value={option.value}
              variant={
                selectedFieldValues.includes(option.value)
                  ? 'contained'
                  : 'outlined'
              }
              onClick={handleOptionClick}
              size="small"
              sx={{
                height: 80,
                textTransform: 'inherit',
                color: selectedFieldValues.includes(option.value)
                  ? '#ffffff'
                  : theme.palette.keyops.gray[600],
                borderColor: theme.palette.keyops.gray[300],
                bgcolor: selectedFieldValues.includes(option.value)
                  ? theme.palette.keyops.blue.midnightBlue
                  : !isMobile
                  ? 'transparent'
                  : theme.palette.keyops.white.main,
                ':hover': {
                  borderColor: theme.palette.keyops.gray[300],
                  bgcolor: selectedFieldValues.includes(option.value)
                    ? theme.palette.keyops.blue.midnightBlue90
                    : theme.palette.keyops.gray[50],
                },
              }}
              fullWidth
              disableElevation
            >
              {option.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <FormControl error={!!error}>
      <Typography color={theme.palette.keyops.black.main}>
        {placeholder}
      </Typography>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={renderInput}
      />
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
      {error && <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default KeyOpsOptionMultiSelect;
