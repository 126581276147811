import React, { useEffect } from 'react';
import {
  Typography,
  useTheme,
  Stack,
  Divider,
  Card,
  LinearProgress,
  useMediaQuery,
  Link,
} from '@mui/material/';
import { Trans, useTranslation } from 'react-i18next';

import RewardsBadge from '../../../components/RewardsBadge';
import { getNextTier } from '../../../utils/functions/reward-utils';
import { RewardTierNames } from '../data/';
import { formatDateMDY } from '../../../utils/functions/common-utils';
import { REWARDS_MARKETING } from '../../../utils/routes';

interface RewardsStatusSectionProps {
  tier: string;
  startDate: string;
  resetDate: string;
  totalRewards: number;
}

const RewardsStatusSection: React.FC<RewardsStatusSectionProps> = ({
  tier,
  startDate,
  resetDate,
  totalRewards,
}: RewardsStatusSectionProps) => {
  const theme = useTheme();
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const nextTier = getNextTier(tier);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    // Linear progress bar animates when set from 0 to x
    setProgress((totalRewards / nextTier.requiredPoints) * 100);
  }, [totalRewards]);

  return (
    <>
      <Card
        sx={{
          mt: 2,
          mx: { md: 0, lg: '5%', xl: '15%' },
          px: { xs: 1, sm: 3, md: 6 },
          py: 3,
          borderRadius: 2,
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);`,
        }}
      >
        <Stack
          justifyContent={'space-between'}
          direction={{ xs: 'column', md: 'row' }}
          width={'100%'}
          spacing={{ xs: 2, md: 5 }}
          divider={
            <Divider
              orientation={isMobileOrTab ? 'horizontal' : 'vertical'}
              flexItem
            />
          }
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
            width={'100%'}
          >
            <Stack
              direction={'row'}
              spacing={3}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RewardsBadge tier={tier} width="52px" height="52px" />
              <Stack direction={'column'}>
                <Typography
                  variant={'h5'}
                  component={'p'}
                  fontWeight={500}
                  color={theme.palette.keyops.blue.midnightBlue}
                  textTransform={'capitalize'}
                >
                  {tier}
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={500}
                  color={theme.palette.keyops.gray[600]}
                >
                  {t('rewards.statusPanel.status')}
                </Typography>
              </Stack>
              <Stack direction={'column'}>
                <Typography
                  variant={'h5'}
                  component={'p'}
                  fontWeight={500}
                  color={theme.palette.keyops.blue.midnightBlue}
                >
                  {totalRewards.toLocaleString()}
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={500}
                  color={theme.palette.keyops.gray[600]}
                >
                  {t('rewards.statusPanel.points')}
                </Typography>
              </Stack>
            </Stack>
            {tier !== RewardTierNames.platinum && (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'90%'}
                spacing={1}
              >
                <LinearProgress
                  sx={{
                    width: '100%',
                    height: '13px',
                    borderRadius: '8px',
                    backgroundColor: theme.palette.keyops.gray[200],
                    '& .MuiLinearProgress-bar': {
                      borderRadius: '8px',
                      backgroundColor: theme.palette.keyops.blue.midnightBlue, // color of the bar
                    },
                  }}
                  variant="determinate"
                  value={progress}
                />
                <RewardsBadge
                  tier={nextTier.tierName}
                  width="24px"
                  height="24px"
                />
              </Stack>
            )}
            <Typography
              variant={'body2'}
              color={theme.palette.keyops.gray[600]}
              fontWeight={500}
              textAlign={'center'}
            >
              {tier === RewardTierNames.platinum ? (
                <>{t('rewards.statusPanel.platinumAchievement')}</>
              ) : (
                <>
                  {t('rewards.statusPanel.nextTier', {
                    requiredPoints: (
                      nextTier.requiredPoints - totalRewards
                    ).toLocaleString(),
                    tier:
                      nextTier.tierName?.charAt(0).toUpperCase() +
                      nextTier.tierName?.slice(1),
                  })}
                </>
              )}
            </Typography>
          </Stack>
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems="center"
            textAlign={'center'}
            spacing={2}
            width={'100%'}
          >
            <Typography
              variant={'body1'}
              fontSize={{ xs: '0.9375rem', sm: '1rem' }}
              color={theme.palette.keyops.black.main}
            >
              {t('rewards.statusPanel.statusStartDate', {
                tier: tier.charAt(0).toUpperCase() + tier.slice(1),
                startDate: formatDateMDY(startDate),
              })}{' '}
            </Typography>
            <Typography
              variant={'body1'}
              fontSize={{ xs: '0.9375rem', sm: '1rem' }}
              color={theme.palette.keyops.black.main}
            >
              {t('rewards.statusPanel.statusResetDate', {
                resetDate: formatDateMDY(resetDate),
              })}
            </Typography>
            <Typography
              variant={'body1'}
              fontSize={{ xs: '0.9375rem', sm: '1rem' }}
              color={theme.palette.keyops.black.main}
            >
              <Trans
                defaults={t('rewards.statusPanel.learnMore')}
                components={{
                  linkTag: (
                    <Link
                      href={REWARDS_MARKETING}
                      underline="none"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
export default RewardsStatusSection;
