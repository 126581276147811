// External Dependencies
import React, { useEffect, useRef, useState } from 'react';
import jwt_decode from 'jwt-decode';
import GoogleButton from '../../../components/GoogleButton';

const GoogleSignUp = ({ setValue, onSubmit }) => {
  const [jwt, setJwt] = useState('');

  const googleButtonRef = useRef(null);

  const handleSignUpResponse = (responseJWT) => {
    if (responseJWT) setJwt(responseJWT);
  };

  useEffect(() => {
    if (jwt) {
      const decodedJWT: {
        email: string;
        given_name: string | null;
        family_name: string | null;
      } = jwt_decode(jwt);
      setValue('continue-with-google', jwt);
      setValue('email', decodedJWT.email, {
        shouldValidate: true,
      });
      setValue('firstName', decodedJWT.given_name, {
        shouldValidate: true,
      });
      setValue('lastName', decodedJWT.family_name, {
        shouldValidate: true,
      });
      onSubmit();
    }
  }, [jwt]);

  return (
    <GoogleButton
      ref={googleButtonRef}
      callbackFunc={handleSignUpResponse}
      shape="rectangular"
      theme="outline"
      size="large"
      text="continue_with"
    />
  );
};

export default GoogleSignUp;
