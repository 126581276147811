// External Dependencies
import * as React from 'react';
import {
  Box,
  Link,
  Typography,
  Alert,
  AlertTitle,
  Collapse,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { axiosCall } from '../../utils/api';
import {
  StyledInputRoot,
  StyledInputElement,
  PageContainer,
  Pad,
} from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import { validEmail } from '../../utils/functions/common-utils';
import { analytics } from '@keyops-hcp/ui-components';

export default function ForgotPassword() {
  const { t } = useTranslation();

  const [values, setValues] = React.useState({
    email: '',
    successText: '',
    error: false,
    hidden: false,
  });

  const handleChange = async (event, field) => {
    await setValues({ ...values, [field]: event.target.value, error: false });
    if (event.keyCode === 13) {
      resetPassword();
    }
  };

  const resetPassword = async () => {
    const email = values.email.trim();
    // TODO make this check for a valid
    if (!validEmail(email)) {
      setValues({
        ...values,
        error: true,
      });
      return;
    }
    try {
      await axiosCall(
        true,
        'post',
        'forgot-password',
        false,
        {},
        {
          email,
        }
      );
      setValues({
        ...values,
        hidden: true,
      });

      analytics.track('Forgot password', { email });
    } catch (e) {
      // Still set things this error only happens then the email isn't in our systems
      setValues({
        ...values,
        successText: t('forgotPassword.success'),
        hidden: true,
      });
      analytics.track('Failed forgot password', { email });
    }
  };

  return (
    // TO DO: revisit password reset logic
    // Update text in lang file
    <PageContainer>
      <Pad>
        <Link href="/login">
          <img style={{ height: 60 }} src={Logo} alt="Keyops Logo" />
        </Link>
      </Pad>
      <Box hidden={values.hidden}>
        <Pad>
          <Typography>{t('forgotPassword.enterPrimaryEmail')}</Typography>
        </Pad>
        <StyledInputRoot>
          <StyledInputElement
            placeholder="Email"
            id="email"
            type="text"
            onChange={(e) => {
              handleChange(e, 'email');
            }}
          />
        </StyledInputRoot>
        <Collapse in={values.error}>
          <Alert severity="warning">
            <AlertTitle>{t('forgotPassword.enterValidEmail')}</AlertTitle>
          </Alert>
        </Collapse>
        <RoundedButton
          fullWidth
          sx={{ marginTop: '25px' }}
          variant="contained"
          onClick={resetPassword}
        >
          {t('forgotPassword.sendInstruction')}
        </RoundedButton>
      </Box>
      <Collapse in={values.hidden} sx={{ pt: 1 }}>
        <Alert severity="success">
          <AlertTitle>{t('forgotPassword.thankYou')}</AlertTitle>
          <strong>
            {' '}
            {t('forgotPassword.willSendEmailNote', {
              email: values.email,
            })}{' '}
            <a href="/help">{t('forgotPassword.contactUs')}</a>.
          </strong>
        </Alert>
      </Collapse>
      <Typography sx={{ marginTop: '25px' }}>
        <Link href="/forgot-email">{t('forgotPassword.forgotUsername')}</Link>
      </Typography>
      <Typography sx={{ marginTop: '25px' }}>
        <Link href="/login">{t('forgotPassword.haveAccount')}</Link>
      </Typography>
    </PageContainer>
  );
}
