import React from 'react';
import {
  Box,
  Drawer,
  List,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { useNavConstants } from '../../custom-hooks/useNavConstants';
import { DASHBOARD } from '../../utils/routes';
import KeyOpsLogo from '../../images/KeyOpsLogo.svg';
import { NavItem } from '../../components/NavItem';

interface OutsideDrawerProps {
  outsideDrawerOpen: boolean;
  toggleOutsideDrawer: (newOpen: boolean) => () => void;
}

const OutsideDrawer: React.FC<OutsideDrawerProps> = ({
  outsideDrawerOpen,
  toggleOutsideDrawer,
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const navigate = useNavigate();

  const { bottomNavItems, topNavItems } = useNavConstants();

  return (
    <Drawer open={outsideDrawerOpen} onClose={toggleOutsideDrawer(false)}>
      <Toolbar
        sx={{
          width: 232,
          borderBottom: `1px solid ${palette.keyops.gray[200]}`,
        }}
      >
        <img
          src={KeyOpsLogo}
          height={32}
          style={{ cursor: 'pointer', flexGrow: 1 }}
          onClick={() => navigate(DASHBOARD)}
          alt="KeyOps-Logo-Full"
        />
      </Toolbar>
      <Box
        display={'flex'}
        flexGrow={1}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <List sx={{ pr: 4 }}>
          {topNavItems.map((navItem) => (
            <NavItem
              key={navItem.name}
              item={navItem}
              handlePathClick={() => (event: React.MouseEvent) => {
                if (navItem.path) {
                  navigate(navItem.path);
                  if (isMobile) {
                    toggleOutsideDrawer(false);
                  }
                } else if (navItem.action) {
                  navItem.action(event);
                  if (isMobile && toggleOutsideDrawer) {
                    toggleOutsideDrawer(false);
                  }
                }
              }}
            />
          ))}
        </List>
        <List sx={{ pr: 4 }}>
          {bottomNavItems.map((navItem) => (
            <NavItem
              key={navItem.name}
              item={navItem}
              handlePathClick={() => (event: React.MouseEvent) => {
                if (navItem.path) {
                  navigate(navItem.path);
                  if (isMobile) {
                    toggleOutsideDrawer(false);
                  }
                } else if (navItem.action) {
                  navItem.action(event);
                  if (isMobile && toggleOutsideDrawer) {
                    toggleOutsideDrawer(false);
                  }
                }
              }}
            />
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default OutsideDrawer;
