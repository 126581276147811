// External Dependencies
import React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';

// Internal Dependencies
import { showHeader } from '../../utils/layout';
import { smBreakPoint } from '../../utils/constants';

const CustomizedHeader = styled('header')`
  position: fixed;
  width: 100%;
  height: 60px;
  background: #f3f9fc;
  z-index: 1000;
  @media (min-width: ${smBreakPoint}) {
    height: 90px;
  }
`;

// TODO: This component is rendered for ROUTES.ONBOARDING_ID route.
// TODO: Make sure this component is not required and remove it.
export default function Login({ action, text }) {
  const location = useLocation();
  if (!showHeader(location.pathname)) return <React.Fragment />;
  return (
    <CustomizedHeader variant="contained" onClick={action}>
      {text}
    </CustomizedHeader>
  );
}
