import React from 'react';
import { NurseUserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';
import { ProfileField } from './ProfileField';
import {
  NurseOccupationOptions,
  NursePracticeSettingDetailOptions,
  NursePracticeSettingOptions,
  NurseSpecialtyOptions,
  StateAndProvinceOptions,
} from '../../../../../utils/enum-display';

export const NursesProfileFields = ({
  userData,
}: {
  userData: NurseUserDto;
}) => {
  const { t } = useTranslation();
  const nurseProfile = userData.hcpProfile;

  const practiceSettingContent =
    NursePracticeSettingOptions.getDisplay(nurseProfile.practiceSetting) +
    (nurseProfile.practiceSettingDetail
      ? '\n ' +
        NursePracticeSettingDetailOptions.getDisplay(
          nurseProfile.practiceSettingDetail
        )
      : '');

  //TODO: just gobbing out values, these all need to get rendered
  // also, going to move all of this to the translations field

  return (
    <>
      <ProfileField
        title={t('profile.profileDetails.memberSince')}
        content={new Date(userData.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      />
      <ProfileField
        title={t('profile.profileDetails.occupation')}
        content={NurseOccupationOptions.getDisplay(nurseProfile.occupation)}
      />
      {nurseProfile.specialty && nurseProfile.specialty.length > 0 && (
        <ProfileField
          title={t('profile.profileDetails.specialty')}
          content={nurseProfile.specialty
            .map((specialty) => NurseSpecialtyOptions.getDisplay(specialty))
            .join(', ')}
        />
      )}
      <ProfileField
        title={t('profile.profileDetails.practiceSetting')}
        content={practiceSettingContent}
      />
      <ProfileField
        title={t('profile.profileDetails.locationOfPractice')}
        content={StateAndProvinceOptions.getDisplay(
          userData.provinceOfPractice
        )}
      />
    </>
  );
};
