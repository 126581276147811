export const showNav = (pathname, authed) => {
  let show = false;

  pathname = pathname.split('/')[1];
  switch (pathname) {
    case '':
      show = false;
      break;
    case 'login':
      show = false;
      break;
    case 'forgot-password':
      show = false;
      break;
    case 'forgot-email':
      show = false;
      break;
    case 'reset-password':
      show = false;
      break;
    case 'onboarding':
      show = false;
      break;
    case 'reset':
      show = false;
      break;
    case 'referral':
      show = false;
      break;
    case 'invitation':
      show = false;
      break;
    case 'engagement':
      show = false;
      break;
    default:
      show = authed ?? false;
      break;
  }

  return show;
};
export const showHeader = (pathname) => {
  let show = true;

  pathname = pathname.split('/')[1];
  switch (pathname) {
    case '':
      show = false;
      break;
    case 'login':
      show = false;
      break;
    case 'forgot-password':
      show = false;
      break;
    case 'forgot-email':
      show = false;
      break;
    case 'reset-password':
      show = false;
      break;
    case 'setup-password':
      show = false;
      break;
    case 'reset':
      show = false;
      break;
    case 'invitation':
      show = false;
      break;
    default:
      show = true;
      break;
  }

  return show;
};
