import React from 'react';
import { useNavigate } from 'react-router';
import { alpha, Box, List, Stack, useTheme } from '@mui/material';

import { KeyOpsLogo } from '@keyops-hcp/ui-components';

import { DASHBOARD } from '../../../utils/routes';
import { useNavConstants } from '../../../custom-hooks/useNavConstants';
import { CustomNavItem } from './CustomNavItem';

export const DesktopSideNavbar = () => {
  const { palette } = useTheme();

  // Build items to display according the user profile
  const { bottomNavItems, topNavItems } = useNavConstants();

  // TODO: do we still need that? For smaller screens?
  // const location = useLocation();
  // const isAdBoardLanding = location.pathname.startsWith('/adboard-landing/');

  const navigate = useNavigate();

  const desktopWidth = 300;

  return (
    <Stack
      direction={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexShrink={0}
      bgcolor={palette.keyops.white.main}
      borderRight={`solid 1px ${alpha(
        palette.keyops.blue.lightSteelBlue,
        0.2
      )}`}
      width={desktopWidth}
      zIndex={2000}
      data-testid="side-navbar"
    >
      {/* Logo */}
      <Stack direction={'column'} alignItems={'baseline'}>
        <Box pl={2} mt={'-28px'} mb={-2}>
          <KeyOpsLogo
            style={{ cursor: 'pointer' }}
            sx={{ fontSize: '124px' }}
            height={'40px'}
            onClick={() => navigate(DASHBOARD)}
          />
        </Box>
        {/* First nav items block (Home, earnings, rewards) */}
        <List sx={{ width: desktopWidth * 0.75 }}>
          {topNavItems.map((navItem) => (
            <CustomNavItem key={navItem.name} item={navItem} />
          ))}
        </List>
      </Stack>

      {/* TODO: add "Open opportunities" block (adboard + survey engagements) */}
      {/* Last nav items block (Contact, log out) */}
      <Box pb={'48px'}>
        <List sx={{ width: desktopWidth * 0.75 }}>
          {bottomNavItems.map((navItem) => (
            <CustomNavItem key={navItem.name} item={navItem} />
          ))}
        </List>
      </Box>
    </Stack>
  );
};
