// TODO this should be moved to the back-end
const RewardTiers = {
  blue: 0,
  silver: 6000,
  gold: 12000,
  platinum: 24000,
};

/*
 * getNextTier gets the next available tier & required point amount
 * NOTE: This function will return Platinum if user is Platinum. We still havent defined the rewards logic for these users
 */
export const getNextTier = (
  tier: string
): { tierName: string; requiredPoints: number } => {
  const tiers = Object.keys(RewardTiers);
  const currentIndex = tiers.indexOf(tier);
  if (currentIndex === -1) {
    throw new Error(`Tier '${tier}' not found in RewardTiers`);
  } else if (currentIndex < tiers.length - 1) {
    const nextTier = tiers[currentIndex + 1];
    return {
      tierName: nextTier,
      requiredPoints: RewardTiers[nextTier],
    };
  } else {
    return {
      tierName: tiers[tiers.length - 1], // Return last tier when user is already at the last tier
      requiredPoints: RewardTiers[tiers[tiers.length - 1]],
    };
  }
};
