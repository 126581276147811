import {
  Countries,
  Ethnicities,
  FullNurseSpecialties,
  Genders,
  HcpTypes,
  NurseCareTasks,
  NurseClinicTypes,
  NurseEmploymentStatuses,
  NurseHospitalSettings,
  NurseInpatientHospitalDepartments,
  NurseOccupations,
  NurseOccupationsCA,
  NurseOccupationsUS,
  NurseOutpatientHospitalDepartments,
  NursePracticeSettingDetails,
  NursePracticeSettings,
  NurseSpecialties,
  PartialNurseSpecialties,
  PhysicianPracticeSettings,
  Province,
  Provinces,
  Specialties,
  Specialty,
  State,
  States,
  SubSpecialties,
  SubSpecialtiesBySpecialty,
  SubSpecialty,
} from '@keyops-hcp/constants';
import i18next from '../../languages/i18n.config';

const appText = i18next.t('enums', { returnObjects: true });

const generateOptions = <T>(
  values: readonly T[],
  enumName: string
): Options<T> => {
  const enumLabels = appText[enumName];
  if (enumLabels) {
    return new Options(
      values.map((value) => {
        const displayValue = enumLabels[value] ?? value; // default to value if label is not found
        return { value: value, label: displayValue };
      })
    );
  }
  return new Options(
    values.map((value) => {
      return { value: value, label: value.toString() };
    })
  );
};

type Option<T> = {
  value: T;
  label: string;
};

export class Options<T> extends Array<Option<T>> {
  constructor(items: Option<T>[]) {
    super();
    if (items && Array.isArray(items)) {
      this.push(...items);
    }
  }
  /**
   *
   * @param T
   * @param value
   */
  public getDisplay(value): string {
    const item = this.find((item) => item.value === value);
    if (item) {
      return item.label;
    }
    value?.toString();
  }
}

export const EngagementOptions = generateOptions(
  [
    'Consulting',
    'Advisory boards',
    'Survey creation and results commentary',
    'Industry presentations and delivery',
  ],
  'engagementTypes'
);
export const BooleanOptions = generateOptions([true, false], 'yesNo');
export const HcpTypeOptions = generateOptions(HcpTypes, 'hcpTypes');
export const CountryOptions = generateOptions(Countries, 'countries');
export const GenderOptions = generateOptions(Genders, 'genders');
/**
 * We have a bunch of users who have the gender of "transgender" already from previous iterations of onboarding.
 *
 * We don't offer it with new onboarding as new onboarding is asking about identity rather than biology.
 * If a person is trans and identifies as a man, we only care about what they identify as, not what their
 * biology is.
 *
 * However we cannot confidently map pre-existing users with "transgender" to any of the existing identity
 * options, e.g. a trans person could identify as man, woman, non-binary, etc... we can't assume any of those.
 *
 * In order to handle this, we do not have it as an onboarding option.
 */
export const OnboardingGenderOptions = generateOptions(
  Genders.filter((gender) => gender !== 'transgender'),
  'genders'
);
export const EthnicityOptions = generateOptions(Ethnicities, 'ethnicities');
export const StateOptions = generateOptions(States, 'states');
export const ProvinceOptions = generateOptions(Provinces, 'provinces');
export const StateAndProvinceOptions = new Options<State | Province>(
  StateOptions
);
StateAndProvinceOptions.push(...ProvinceOptions);
export const SpecialtyOptions = generateOptions(Specialties, 'specialties');
export const SubSpecialtyOptionsBySpecialty: Partial<
  Record<Specialty, Options<SubSpecialty>>
> = {};
Object.entries(SubSpecialtiesBySpecialty).forEach(
  ([specialty, subSpecialties]) => {
    SubSpecialtyOptionsBySpecialty[specialty] = generateOptions(
      subSpecialties,
      'subSpecialties'
    );
  }
);
export const SubSpecialtyOptions = generateOptions(
  SubSpecialties,
  'subSpecialties'
);
export const PhysicianPracticeSettingOptions = generateOptions(
  PhysicianPracticeSettings,
  'physicianPracticeSettings'
);

// Generate Nurses onboarding options
export const NurseOccupationCAOptions = generateOptions(
  NurseOccupationsCA,
  'nurseOccupations'
);
export const NurseOccupationUSOptions = generateOptions(
  NurseOccupationsUS,
  'nurseOccupations'
);
export const NurseOccupationOptions = generateOptions(
  NurseOccupations,
  'nurseOccupations'
);
export const NurseEmploymentStatusOptions = generateOptions(
  NurseEmploymentStatuses,
  'nurseEmploymentStatuses'
);
export const NursePracticeSettingOptions = generateOptions(
  NursePracticeSettings,
  'nursePracticeSettings'
);
export const PartialNurseSpecialtyOptions = generateOptions(
  PartialNurseSpecialties,
  'nurseSpecialties'
);
export const FullNurseSpecialtyOptions = generateOptions(
  FullNurseSpecialties,
  'nurseSpecialties'
);
export const NurseSpecialtyOptions = generateOptions(
  NurseSpecialties,
  'nurseSpecialties'
);
// The options below are all for nurse practice setting details
export const NurseHospitalSettingOptions = generateOptions(
  NurseHospitalSettings,
  'nurseHospitalSettings'
);
export const NurseInpatientHospitalDepartmentOptions = generateOptions(
  NurseInpatientHospitalDepartments,
  'nursePracticeSettingDetails'
);
export const NurseOutpatientHospitalDepartmentOptions = generateOptions(
  NurseOutpatientHospitalDepartments,
  'nursePracticeSettingDetails'
);
export const NurseCareTaskOptions = generateOptions(
  NurseCareTasks,
  'nurseCareTasks'
);
export const NurseClinicTypeOptions = generateOptions(
  NurseClinicTypes,
  'nurseClinicTypes'
);
export const NursePracticeSettingDetailOptions = generateOptions(
  NursePracticeSettingDetails,
  'nursePracticeSettingDetails'
);
