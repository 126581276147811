// External Dependencies
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Link,
  Typography,
  Alert,
  AlertTitle,
  Collapse,
} from '@mui/material';

// Internal Dependencies
import { axiosCall } from '../../utils/api';
import {
  StyledInputRoot,
  StyledInputElement,
  PageContainer,
  Pad,
} from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import { analytics } from '@keyops-hcp/ui-components';

export default function ForgotEmail() {
  const { t } = useTranslation();

  const [values, setValues] = React.useState({
    physicianNum: '',
    successText: '',
    error: false,
    hidden: false,
  });

  const handleChange = async (event, field) => {
    await setValues({ ...values, [field]: event.target.value, error: false });
    if (event.keyCode === 13) {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    const { physicianNum } = values;
    if (values.physicianNum === '') {
      setValues({
        ...values,
        error: true,
      });
    } else {
      try {
        const results = await axiosCall(
          true,
          'post',
          'auth/send-email-by-physician-num',
          false,
          {},
          {
            physicianNum,
          }
        );

        analytics.track('Forgot email', { physicianNum });
        console.log(results);

        setValues({
          ...values,
          hidden: true,
        });
      } catch (e) {
        analytics.track('Forgot email failed', { physicianNum });

        // Still set things this error only happens then the email isn't in our systems
        setValues({
          ...values,
          successText: t('forgotEmail.success'),
          hidden: true,
        });
      }
    }
  };

  return (
    // TO DO: revisit password reset logic
    // Update text in lang file
    <PageContainer style={{ textAlign: 'center' }}>
      <Pad>
        <Link href="/login">
          <img
            style={{ height: 60 }}
            src={Logo}
            alt={t('general.keyOpsLogoAltText')}
          />
        </Link>
      </Pad>
      <Box hidden={values.hidden}>
        <Pad>
          <Typography>{t('forgotEmail.collegeLicenseNumber')}</Typography>
        </Pad>
        <StyledInputRoot>
          <StyledInputElement
            placeholder={t('forgotEmail.physicianNumberPlaceHolder')}
            id="physicianNum"
            type="number"
            onChange={(e) => {
              handleChange(e, 'physicianNum');
            }}
          />
        </StyledInputRoot>
        <Collapse in={values.error}>
          <Alert severity="warning">
            <AlertTitle>{t('forgotEmail.enterNumber')}</AlertTitle>
          </Alert>
        </Collapse>
        <RoundedButton
          fullWidth
          sx={{ marginTop: '25px' }}
          variant="contained"
          onClick={sendEmail}
        >
          {' '}
          {t('forgotEmail.sendEmail')}
        </RoundedButton>
        <Typography sx={{ marginTop: '25px' }}>
          <Link href="/forgot-password">{t('forgotEmail.forgotPassword')}</Link>
        </Typography>
        <Typography sx={{ marginTop: '25px' }}>
          <Link href="/login">{t('forgotEmail.haveAccount')}</Link>
        </Typography>
      </Box>

      <Collapse in={values.hidden} sx={{ pt: 1 }}>
        <Alert severity="success">
          <AlertTitle>{t('forgotEmail.thankYou')}</AlertTitle>
          <strong>
            {' '}
            {t('forgotEmail.willSendEmailNote', {
              physicianNum: values.physicianNum,
            })}{' '}
            <a href="/help">{t('forgotEmail.contactUs')}</a>.
          </strong>
        </Alert>
        <Typography sx={{ marginTop: '25px' }}>
          <Link href="/login">{t('forgotEmail.returnToLogin')}</Link>
        </Typography>
      </Collapse>
    </PageContainer>
  );
}
