import React, { createContext, useContext, useState } from 'react';

interface ISurveyContext {
  surveyIframeRendered: boolean;
  setSurveyIframeRendered: (value: boolean) => void;
  saveAndResumeActive: boolean;
  setSaveAndResumeActive: (value: boolean) => void;
}

const SurveyContext = createContext<ISurveyContext | undefined>(undefined);

export const useSurveyContext = () => {
  return useContext(SurveyContext);
};

export const SurveyContextProvider = ({ children }) => {
  const [surveyIframeRendered, setSurveyIframeRendered] = useState(false);
  const [saveAndResumeActive, setSaveAndResumeActive] = useState(false);

  return (
    <SurveyContext.Provider
      value={{
        surveyIframeRendered,
        setSurveyIframeRendered,
        saveAndResumeActive,
        setSaveAndResumeActive,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
