import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { NavItemType } from '../../../custom-hooks/useNavConstants';
import { useSurveyContext } from '../SurveyContext';
import ExitEngagementModal from './ExitEngagementModal';
import { NavItem } from '../../../components/NavItem';

interface CustomNavItemProps {
  item: NavItemType;
  toggleDrawer?: (newOpen: boolean) => void;
  isMobileBottomNavbar?: boolean; // Different style when nav items are displayed in mobile
  isMobileBottomDrawer?: boolean;
}

export const CustomNavItem: React.FC<CustomNavItemProps> = ({
  item,
  isMobileBottomNavbar = false,
  isMobileBottomDrawer = false,
}) => {
  const navigate = useNavigate();
  const { surveyIframeRendered, saveAndResumeActive } = useSurveyContext();
  const [openModal, setOpenModal] = useState(false);

  const handlePathClick = () => (event: React.MouseEvent) => {
    if (item.path) {
      if (surveyIframeRendered && !saveAndResumeActive) {
        setOpenModal(true);
        return;
      }
      navigate(item.path);
    } else if (item.action) {
      item.action(event);
    }
  };

  return (
    <>
      <ExitEngagementModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        navigateFn={() => navigate(item.path)}
      />
      <NavItem
        item={item}
        handlePathClick={handlePathClick}
        isMobileBottomNavbar={isMobileBottomNavbar}
        isMobileBottomDrawer={isMobileBottomDrawer}
      />
    </>
  );
};
