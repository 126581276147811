import {
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

export type EngagementCardFooterProps = {
  items: JSX.Element[];
};
export const EngagementCardFooter = ({ items }: EngagementCardFooterProps) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const EngagementCardFooter = styled(Stack)(() => ({
    paddingTop: '8px',
    color: palette.keyops.blue.lightSteelBlue,
    borderTop: isMobile ? undefined : '1px solid #F7F7F7',
    fontSize: '14px',
  }));
  return (
    <EngagementCardFooter
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? 0.5 : 1}
      divider={
        isMobile ? undefined : (
          <Typography fontSize={'12px'}>&#x25CF;</Typography>
        )
      }
    >
      {items.map((item, index) => (
        <Stack
          direction="row"
          spacing={0.5}
          className="textWithIcons"
          key={index}
          color={palette.keyops.black.main}
        >
          {item}
        </Stack>
      ))}
    </EngagementCardFooter>
  );
};
