import { axiosCall } from '../../utils/api';
import i18next from '../../languages/i18n.config';
import {
  VALIDATE_EMAIL,
  VALIDATE_LICENSE_NUMBER,
  VALIDATE_WORK_EMAIL,
} from '../../utils/api-routes';
import { FORM_FIELDS } from './forms/form-constants';

// email pattern taken from https://stackoverflow.com/a/201378/229743
const emailPattern =
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x21-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x21-\x7f])+)\])$/;
const invalidCharacterPattern = /[^0-9]/;
const licenseNumberPattern = /^\d{5,10}$/;
const yearPattern = /^(?:(?:19|20)[0-9]{2})$/;
const mobileNumberPattern =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const setAllErrors = (errors, setError) => {
  const processedFieldNames = new Set();
  errors.forEach(({ name, type, message }) => {
    if (!processedFieldNames.has(name)) {
      setError(name, { type, message }, { shouldFocus: true });
      processedFieldNames.add(name);
    }
  });
};

export const validateLandingForm = async ({ emailValue, setError }) => {
  const errors = [];

  // pattern validation
  const patternValid = emailPattern.test(emailValue);
  if (!patternValid)
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.landingPage.email.name,
      message: i18next.t(`onboarding.forms.fieldErrors.emailPattern`),
    });

  // email server validation
  const results = await axiosCall(true, 'get', VALIDATE_EMAIL, false, {
    email: emailValue,
  });
  if (results?.status !== 200)
    errors.push({
      type: 'server error',
      name: FORM_FIELDS.landingPage.email.name,
      message: i18next.t(`onboarding.forms.fieldErrors.emailUsed`),
    });

  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }
  return true;
};

export const validateAccountDetailsForm = async ({
  firstNameValue,
  lastNameValue,
  cityOfPracticeValue,
  setError,
}) => {
  const errors = [];

  // pattern validation
  const firstNamePatternValid = invalidCharacterPattern.test(firstNameValue);
  const lastNamePatternValid = invalidCharacterPattern.test(lastNameValue);
  const cityOfPracticePatternValid =
    invalidCharacterPattern.test(cityOfPracticeValue);

  if (!firstNamePatternValid)
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.accountDetails.firstName.name,
      message: i18next.t(`onboarding.forms.fieldErrors.invalidCharacter`),
    });
  if (!lastNamePatternValid)
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.accountDetails.lastName.name,
      message: i18next.t(`onboarding.forms.fieldErrors.invalidCharacter`),
    });
  if (!cityOfPracticePatternValid)
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.accountDetails.cityOfPractice.name,
      message: i18next.t(`onboarding.forms.fieldErrors.invalidCharacter`),
    });

  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }
  return true;
};

export const validatePhysicianPracticeDetailsForm = async ({
  physician,
  setError,
}) => {
  const errors = [];
  const {
    licenseNumber,
    practiceLicenseYear,
    bypassWorkEmailVerification,
    workEmail,
  } = physician;

  if (!licenseNumberPattern.test(licenseNumber))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.physician.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licensePattern`),
    });
  if (!yearPattern.test(practiceLicenseYear))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.physician.practiceLicenseYear.name,
      message: i18next.t(`onboarding.forms.fieldErrors.yearPattern`),
    });
  if (!bypassWorkEmailVerification && !emailPattern.test(workEmail))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.physician.workEmail.name,
      message: i18next.t(`onboarding.forms.fieldErrors.emailPattern`),
    });
  if (!bypassWorkEmailVerification) {
    // work email server validation
    const results = await axiosCall(true, 'get', VALIDATE_WORK_EMAIL, false, {
      workEmail: workEmail,
    });
    if (results?.status !== 200)
      errors.push({
        type: 'server error',
        name: FORM_FIELDS.practiceDetails.physician.workEmail.name,
        message: i18next.t(`onboarding.forms.fieldErrors.workEmailNotValid`),
      });
  }
  // license number server validation
  const results = await axiosCall(true, 'get', VALIDATE_LICENSE_NUMBER, false, {
    licenseNumber: licenseNumber,
    hcpType: 'physician',
  });
  if (results?.status !== 200)
    errors.push({
      type: 'server error',
      name: FORM_FIELDS.practiceDetails.physician.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licenseNotValid`),
    });

  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }

  return true;
};
export const validatePharmacistPracticeDetailsForm = async ({
  pharmacist,
  setError,
}) => {
  const errors = [];
  const { licenseNumber, practiceLicenseYear } = pharmacist;
  // pharmacist validation
  if (!licenseNumberPattern.test(licenseNumber))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.pharmacist.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licensePattern`),
    });
  if (!yearPattern.test(practiceLicenseYear))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.pharmacist.practiceLicenseYear.name,
      message: i18next.t(`onboarding.forms.fieldErrors.yearPattern`),
    });
  // license number server validation
  const results = await axiosCall(true, 'get', VALIDATE_LICENSE_NUMBER, false, {
    licenseNumber: licenseNumber,
    hcpType: 'pharmacist',
  });
  if (results?.status !== 200)
    errors.push({
      type: 'server error',
      name: FORM_FIELDS.practiceDetails.pharmacist.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licenseNotValid`),
    });
  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }

  return true;
};
export const validateNursePracticeDetailsForm = async ({ nurse, setError }) => {
  const errors = [];
  const {
    licenseNumber,
    practiceLicenseYear,
    bypassWorkEmailVerification,
    workEmail,
  } = nurse;

  if (!licenseNumberPattern.test(licenseNumber))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.nurse.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licensePattern`),
    });
  if (!yearPattern.test(practiceLicenseYear))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.nurse.practiceLicenseYear.name,
      message: i18next.t(`onboarding.forms.fieldErrors.yearPattern`),
    });
  if (!bypassWorkEmailVerification && !emailPattern.test(workEmail))
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.practiceDetails.nurse.workEmail.name,
      message: i18next.t(`onboarding.forms.fieldErrors.emailPattern`),
    });

  if (!bypassWorkEmailVerification) {
    // work email server validation
    const results = await axiosCall(true, 'get', VALIDATE_WORK_EMAIL, false, {
      workEmail: workEmail,
    });
    if (results?.status !== 200)
      errors.push({
        type: 'server error',
        name: FORM_FIELDS.practiceDetails.nurse.workEmail.name,
        message: i18next.t(`onboarding.forms.fieldErrors.workEmailNotValid`),
      });
  }
  // license number server validation
  const results = await axiosCall(true, 'get', VALIDATE_LICENSE_NUMBER, false, {
    licenseNumber: licenseNumber,
    hcpType: 'nurse',
  });
  if (results?.status !== 200)
    errors.push({
      type: 'server error',
      name: FORM_FIELDS.practiceDetails.nurse.licenseNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.licenseNotValid`),
    });

  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }

  return true;
};

export const validateEngageWithKeyopsForm = async ({
  smsNotificationsValue,
  mobileNumberValue,
  setError,
}) => {
  const errors = [];

  // pattern validation
  const mobileNumberPatternValid = mobileNumberPattern.test(mobileNumberValue);

  if (!!smsNotificationsValue && !mobileNumberPatternValid)
    errors.push({
      type: 'pattern',
      name: FORM_FIELDS.engageWithKeyops.mobileNumber.name,
      message: i18next.t(`onboarding.forms.fieldErrors.mobilePattern`),
    });

  if (errors.length > 0) {
    setAllErrors(errors, setError);
    return false;
  }
  return true;
};

export const validateFields = ({ getValues, setError, currentForm }) => {
  const fieldValues = getValues();
  switch (currentForm) {
    case 0:
      return validateLandingForm({
        emailValue: fieldValues[FORM_FIELDS.landingPage.email.name],
        setError,
      });
    case 1:
      return validateAccountDetailsForm({
        firstNameValue: fieldValues[FORM_FIELDS.accountDetails.firstName.name],
        lastNameValue: fieldValues[FORM_FIELDS.accountDetails.lastName.name],
        cityOfPracticeValue:
          fieldValues[FORM_FIELDS.accountDetails.cityOfPractice.name],
        setError,
      });
    case 2:
      return true;
    case 3:
      switch (fieldValues[FORM_FIELDS.practiceDetails.hcpType.name]) {
        case 'physician': {
          return validatePhysicianPracticeDetailsForm({
            physician: fieldValues.physician,
            setError,
          });
        }
        case 'pharmacist': {
          return validatePharmacistPracticeDetailsForm({
            pharmacist: fieldValues.pharmacist,
            setError,
          });
        }
        case 'nurse': {
          return validateNursePracticeDetailsForm({
            nurse: fieldValues.nurse,
            setError,
          });
        }
        default:
          return false;
      }
    case 4:
      return validateEngageWithKeyopsForm({
        smsNotificationsValue:
          fieldValues[FORM_FIELDS.engageWithKeyops.smsNotifications.name],
        mobileNumberValue:
          fieldValues[FORM_FIELDS.engageWithKeyops.mobileNumber.name],
        setError,
      });
    default:
      return;
  }
};
