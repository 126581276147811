import React from 'react';
import {
  Box,
  Container,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import Img404 from '../../../images/404image.png';
import KeyOpsLogo from '../../../images/KeyOpsLogo.svg';
import { DASHBOARD, LOGIN } from '../../../utils/routes';
import { KeyopsSubTitle1 } from '@keyops-hcp/ui-components';
import { SURVEY_EMAIL_ADDRESS } from '../../../utils/constants';

const UnAuthedNotFoundVisual = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      height={'100vh'}
    >
      <Toolbar
        data-testid="desktop-top-navbar"
        sx={{ justifyContent: !isMobile ? 'space-between' : 'center' }}
      >
        <Box py={2} px={4}>
          <img
            src={KeyOpsLogo}
            width={!isMobile ? '160px' : '136px'}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(DASHBOARD)}
            alt="KeyOps-Logo-Full"
          />
        </Box>
        {!isMobile && (
          <KeyopsSubTitle1 pr={4}>
            <Trans
              defaults={t('unAuthedNotFound.haveAccount')}
              components={{
                linkTag: <Link href={LOGIN} underline="none" />,
              }}
            />
          </KeyopsSubTitle1>
        )}
      </Toolbar>

      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Img404}
          alt="404"
          style={{
            width: '70%',
          }}
        />
        <Typography
          component={'h2'}
          fontSize={56}
          fontWeight={600}
          color={theme.palette.keyops.blue.keyopsBlue}
          mt={-4}
          gutterBottom={!isMobile}
        >
          {t('unAuthedNotFound.errorCode')}
        </Typography>
        <Typography
          variant={!isMobile ? 'h5' : 'h6'}
          textAlign={'center'}
          fontWeight={600}
          gutterBottom
        >
          {t('unAuthedNotFound.title')}
        </Typography>
        <Typography
          variant={!isMobile ? 'body1' : 'body2'}
          textAlign={'center'}
          px={!isMobile ? 4 : 2}
          color={theme.palette.grey[700]}
          gutterBottom
        >
          <Trans
            defaults={t('unAuthedNotFound.subtitle')}
            components={{
              linkTag: (
                <Link
                  href={`mailto:${SURVEY_EMAIL_ADDRESS}`}
                  underline="none"
                />
              ),
            }}
          />
        </Typography>
      </Container>
      <Toolbar sx={{ justifyContent: 'center' }}>
        {isMobile && (
          <KeyopsSubTitle1 pr={4}>
            <Trans
              defaults={t('unAuthedNotFound.haveAccount')}
              components={{
                linkTag: <Link href={LOGIN} underline="none" />,
              }}
            />
          </KeyopsSubTitle1>
        )}
      </Toolbar>
    </Box>
  );
};

export default UnAuthedNotFoundVisual;
