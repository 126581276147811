// External Dependencies
import React, { useMemo } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { ActiveSurveyCard } from './ActiveSurveyCard';
import {
  getActiveAdBoardInvitations,
  getActiveInvitations,
  getCompletedInvitationsWithReferrals,
} from '../../../../../utils/functions/invitation-utils';
import { theme } from '@keyops-hcp/ui-components';
import { ActiveAdBoardCard } from './ActiveAdBoardCard';
import ReferralOpportunitiesCard from './ReferralOpportunitiesCard';

type ActiveEngagementsSectionProps = {
  userData: UserDto;
  engagementData: EngagementData;
};

const ActiveEngagementsSection = ({
  userData,
  engagementData,
}: ActiveEngagementsSectionProps) => {
  const { t } = useTranslation();

  // Functions
  const activeAdBoards = useMemo(
    () => getActiveAdBoardInvitations(engagementData.adBoardInvitations),
    [engagementData]
  );
  const activeSurveys = useMemo(
    () => getActiveInvitations(engagementData.surveyInvitations),
    [engagementData]
  );

  const engagementsWithReferrals = useMemo(
    () =>
      getCompletedInvitationsWithReferrals(engagementData.surveyInvitations),
    [engagementData]
  );

  // Render
  //get cards for adboards
  const cards = activeAdBoards.map((invite) => (
    <ActiveAdBoardCard key={'adBoard' + invite.id} adBoardInvite={invite} />
  ));
  //add cards for surveys
  if (activeSurveys) {
    cards.push(
      ...activeSurveys.map(({ engagementId, engagement, state }) => {
        return (
          <ActiveSurveyCard
            key={'survey' + engagementId} //unique key for each child
            engagementId={engagementId}
            title={engagement.title}
            payout={engagement.payoutValue}
            duration={engagement.estimatedTime}
            state={state}
            points={engagement.rewardValue}
          />
        );
      })
    );
  }
  //add cards for referrals
  if (userData.hcpType !== 'moderator' && engagementsWithReferrals) {
    cards.push(
      ...engagementsWithReferrals.map(({ engagementId, engagement }) => {
        return (
          <ReferralOpportunitiesCard
            key={'referral' + engagementId}
            engagementId={engagementId}
            title={engagement.title}
            payout={engagement.referralPayout}
            points={engagement.referralRewardValue}
          />
        );
      })
    );
  }

  return (
    <>
      {cards.length > 0 ? (
        <>
          <Grid columnSpacing={3} rowSpacing={2} container>
            {cards.map((card, index) => (
              <Grid key={index} item xs={12}>
                {card}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box
          border={'1px solid ' + theme.palette.keyops.blue.lightSteelBlue20}
          textAlign={'center'}
        >
          <Stack spacing={2} my={3}>
            <Typography>
              {t('dashboard.activeEngagementsSection.emptyMessageHeader')}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.keyops.blue.lightSteelBlue,
              }}
            >
              {t('dashboard.activeEngagementsSection.emptyMessage')}
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ActiveEngagementsSection;
