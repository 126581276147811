import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useToast } from './useToast';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const Toast = () => {
  const {
    showToast,
    toastType,
    toastVariant,
    toastMessage,
    toastDuration,
    clearToast,
  } = useToast();

  if (!showToast) return null;

  return (
    <Snackbar
      open={showToast}
      autoHideDuration={toastDuration}
      onClose={clearToast}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={clearToast}
        variant={toastVariant}
        severity={toastType}
        sx={{ width: '100%' }}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
