import {
  Navigate,
  Outlet,
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useAuth } from '../useAuth';
import Loading from '../../components/Loading';
import { DASHBOARD } from '../../utils/routes';

const PublicRoutes = () => {
  const { authed, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }
  return authed ? (
    <Navigate
      preserveQueryString={true}
      to={{
        pathname: DASHBOARD,
        search: `?${createSearchParams(searchParams)}`,
        state: { ...location.state },
      }}
      replace
    />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
