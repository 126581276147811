import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopSideNavbar } from './DesktopSideNavbar';
import { MobileBottomNavBar } from './MobileBottomNavBar';

/**
 * CustomSideNavBar displays the nav items (Contact us, Logout, ...)
 *  - on desktop: in  a side navbar
 *  - on mobile: in a bottom drawer, as a lighter version
 */
export const CustomSideNavBar = ({
  openMobileBottomNavDrawer,
  toggleMobileBottomNavDrawer,
}: {
  openMobileBottomNavDrawer: boolean;
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return isMobile ? (
    <MobileBottomNavBar
      openMobileBottomNavDrawer={openMobileBottomNavDrawer}
      toggleMobileBottomNavDrawer={toggleMobileBottomNavDrawer}
    />
  ) : (
    <DesktopSideNavbar />
  );
};
