import React from 'react';
import { PharmacistUserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

import { ProfileField } from './ProfileField';
import {
  getCAPharmacistPracticeSettingLabel,
  getCAPharmacistRetailChainLabel,
  getPharmacistTypeLabel,
  getUSPharmacistPracticeSettingLabel,
  getUSPharmacistPracticeSpecialtyLabel,
  getUSPharmacistRetailChainLabel,
} from '../../../../../utils/functions/common-utils';
import { StateAndProvinceOptions } from '../../../../../utils/enum-display';

export const PharmacistsProfileFields = ({
  userData,
}: {
  userData: PharmacistUserDto;
}) => {
  const { t } = useTranslation();

  const pharmacistProfile = userData.hcpProfile;

  const displayOtherPracticeSetting =
    pharmacistProfile.practiceSetting === 'other';

  const displayPracticeSpecialty =
    pharmacistProfile.practiceSetting === 'clinical_specialty';
  const displayOtherPracticeSpecialty =
    displayPracticeSpecialty && pharmacistProfile.practiceSpecialty === 'other';

  const displayRetailChain =
    (userData.country === 'US' &&
      pharmacistProfile.practiceSetting === 'community_retail') ||
    (userData.country === 'CA' &&
      pharmacistProfile.practiceSetting === 'retail');
  const displayOtherRetailChain =
    displayRetailChain &&
    ['other', 'independent_pharmacy'].includes(pharmacistProfile.retailChain);

  return (
    <>
      <ProfileField
        title={t('profile.profileDetails.memberSince')}
        content={new Date(userData.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      />
      <ProfileField
        title={t('profile.profileDetails.practiceSetting')}
        content={
          <>
            {userData.country === 'US' &&
              getUSPharmacistPracticeSettingLabel(
                pharmacistProfile.practiceSetting
              )}
            {userData.country === 'CA' &&
              getCAPharmacistPracticeSettingLabel(
                pharmacistProfile.practiceSetting
              )}
          </>
        }
      />
      {displayOtherPracticeSetting && (
        <ProfileField
          title={t('profile.profileDetails.otherPracticeSetting')}
          content={pharmacistProfile.otherPracticeSetting}
        />
      )}
      {displayPracticeSpecialty && (
        <ProfileField
          title={t('profile.profileDetails.practiceSpecialty')}
          content={
            userData.country === 'US' &&
            getUSPharmacistPracticeSpecialtyLabel(
              pharmacistProfile.practiceSpecialty
            )
          }
        />
      )}
      {displayOtherPracticeSpecialty && (
        <ProfileField
          title={t('profile.profileDetails.otherPracticeSpecialty')}
          content={pharmacistProfile.otherPracticeSpecialty}
        />
      )}
      {displayRetailChain && (
        <ProfileField
          title={t('profile.profileDetails.retailChain')}
          content={
            <>
              {userData.country === 'US' &&
                getUSPharmacistRetailChainLabel(pharmacistProfile.retailChain)}
              {userData.country === 'CA' &&
                getCAPharmacistRetailChainLabel(pharmacistProfile.retailChain)}
            </>
          }
        />
      )}
      {displayOtherRetailChain && (
        <ProfileField
          title={t('profile.profileDetails.otherRetailChain')}
          content={pharmacistProfile.otherRetailChain}
        />
      )}
      <ProfileField
        title={t('profile.profileDetails.practiceType')}
        content={getPharmacistTypeLabel(pharmacistProfile.pharmacistType)}
      />
      <ProfileField
        title={t('profile.profileDetails.locationOfPractice')}
        content={StateAndProvinceOptions.getDisplay(
          userData.provinceOfPractice
        )}
      />
    </>
  );
};
