// External Dependencies
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import KeyOpsSingleSelect from '../components/SingleSelect';
import { FORM_FIELDS } from './form-constants';
import {
  CA_PHARMACIST_PRACTICE_SETTINGS,
  CA_PHARMACY_RETAIL_CHAINS,
  US_PHARMACIST_PRACTICE_SETTINGS,
  US_PHARMACIST_PRACTICE_SPECIALTIES,
  US_PHARMACY_RETAIL_CHAINS,
} from '../constants/practiceSettings';
import KeyOpsTextField from '../components/TextField';
import KeyOpsRadioButtonGroup from '../components/RadioButtonGroup';
import { PHARMACIST_TYPES } from '../constants/pharmacistTypes';

const formFields = FORM_FIELDS.practiceDetails.pharmacist;

const PharmacistPracticeDetails = ({
  control,
  register,
  unregister,
  errors,
}) => {
  const { t } = useTranslation();
  const [
    countryValue,
    practiceSettingValue,
    practiceSpecialtyValue,
    retailChainValue,
  ] = useWatch({
    control,
    name: [
      FORM_FIELDS.accountDetails.country.name,
      formFields.practiceSetting.name,
      formFields.practiceSpecialty.name,
      formFields.retailChain.name,
    ],
  });

  // Boolean display values
  const displayOtherPracticeSetting = practiceSettingValue === 'other';
  const displayPracticeSpecialty =
    practiceSettingValue === 'clinical_specialty';
  const displayOtherPracticeSpecialty =
    displayPracticeSpecialty && practiceSpecialtyValue === 'other';
  const displayRetailChain =
    (countryValue === 'US' && practiceSettingValue === 'community_retail') ||
    (countryValue === 'CA' && practiceSettingValue === 'retail');
  const displayOtherRetailChain =
    displayRetailChain &&
    ['other', 'independent_pharmacy'].includes(retailChainValue);

  // onChange functions
  const onPracticeSettingChange = () => {
    if (!displayOtherPracticeSetting) {
      unregister(formFields.otherPracticeSetting.name);
    }
    if (!displayPracticeSpecialty) {
      unregister(formFields.practiceSpecialty.name);
      unregister(formFields.otherPracticeSpecialty.name);
    }
    if (!displayRetailChain) {
      unregister(formFields.retailChain.name);
      unregister(formFields.otherRetailChain.name);
    }
  };
  const onPracticeSpecialtyChange = () => {
    if (!displayOtherPracticeSpecialty) {
      unregister(formFields.otherPracticeSpecialty.name);
    }
  };
  const onRetailChainChange = () => {
    if (!displayOtherRetailChain) {
      unregister(formFields.otherRetailChain.name);
    }
  };

  return (
    <>
      <KeyOpsSingleSelect
        fieldName={formFields.practiceSetting.name}
        placeholder={
          countryValue === 'CA'
            ? formFields.practiceSetting.label
            : 'Primary practice'
        }
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field:
              countryValue === 'CA'
                ? formFields.practiceSetting.label
                : 'Primary practice',
          }),
        }}
        options={
          countryValue === 'CA'
            ? CA_PHARMACIST_PRACTICE_SETTINGS
            : US_PHARMACIST_PRACTICE_SETTINGS
        }
        onChange={onPracticeSettingChange}
        control={control}
        errors={errors}
      />
      {displayOtherPracticeSetting && (
        <KeyOpsTextField
          fieldName={formFields.otherPracticeSetting.name}
          placeholder={formFields.otherPracticeSetting.label}
          rules={{
            required: t(`onboarding.forms.fieldErrors.required`, {
              field: formFields.otherPracticeSetting.label,
            }),
          }}
          register={register}
          errors={errors}
        />
      )}
      {displayPracticeSpecialty && (
        <KeyOpsSingleSelect
          fieldName={formFields.practiceSpecialty.name}
          placeholder={formFields.practiceSpecialty.label}
          rules={{
            required: t(`onboarding.forms.fieldErrors.required`, {
              field: formFields.practiceSpecialty.label,
            }),
          }}
          options={US_PHARMACIST_PRACTICE_SPECIALTIES}
          onChange={onPracticeSpecialtyChange}
          control={control}
          errors={errors}
        />
      )}
      {displayOtherPracticeSpecialty && (
        <KeyOpsTextField
          fieldName={formFields.otherPracticeSpecialty.name}
          placeholder={formFields.otherPracticeSpecialty.label}
          rules={{
            required: t(`onboarding.forms.fieldErrors.required`, {
              field: formFields.otherPracticeSpecialty.label,
            }),
          }}
          register={register}
          errors={errors}
        />
      )}
      {displayRetailChain && (
        <KeyOpsSingleSelect
          fieldName={formFields.retailChain.name}
          placeholder={formFields.retailChain.label}
          rules={{
            required: t(`onboarding.forms.fieldErrors.required`, {
              field: formFields.retailChain.label,
            }),
          }}
          options={
            countryValue === 'CA'
              ? CA_PHARMACY_RETAIL_CHAINS
              : US_PHARMACY_RETAIL_CHAINS
          }
          onChange={onRetailChainChange}
          control={control}
          errors={errors}
        />
      )}
      {displayOtherRetailChain && (
        <KeyOpsTextField
          fieldName={formFields.otherRetailChain.name}
          placeholder={formFields.otherRetailChain.label}
          rules={{
            required: t(`onboarding.forms.fieldErrors.required`, {
              field: formFields.otherRetailChain.label,
            }),
          }}
          register={register}
          errors={errors}
        />
      )}

      <KeyOpsRadioButtonGroup
        fieldName={formFields.pharmacistType.name}
        placeholder={formFields.pharmacistType.label}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.pharmacistType.label,
          }),
        }}
        options={PHARMACIST_TYPES}
        control={control}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.licenseNumber.name}
        placeholder={formFields.licenseNumber.label}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.licenseNumber.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.practiceLicenseYear.name}
        placeholder={'License issuance year'}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: 'license issuance year',
          }),
        }}
        register={register}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.otherDetails.name}
        placeholder={formFields.otherDetails.label}
        register={register}
        errors={errors}
      />
    </>
  );
};

export default PharmacistPracticeDetails;
