import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import Logo from '../../images/KeyOpsLogo.svg';
import { LogoImage } from './styles/';

const Marketing = ({
  currentForm,
  marketingContent,
}: {
  currentForm: number;
  marketingContent: ReactNode[];
}) => (
  <Box
    flexBasis={'45%'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'space-between'}
    gap={4}
    py={2}
  >
    <Box alignSelf={'flex-start'} pl={4}>
      <LogoImage src={Logo} />
    </Box>
    <Stack spacing={3} width={'50%'}>
      {marketingContent[currentForm]}
    </Stack>
    <Box />
  </Box>
);

export default Marketing;
