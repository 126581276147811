import React from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useContactUI } from '../../higher-order-components/ContactUIContext';
import BasicModal from '../BasicModal';
import Loading from '../Loading';
import { KeyopsHeader1 } from '@keyops-hcp/ui-components';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid var(--gray-200, #E2E8F0)',
    },
  },
});

const ContactModal = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const {
    isContactUIOpen,
    closeContactUI,
    contactUITitle,
    contactUIDescription,
    feedback,
    onFeedbackChange,
    isLoading,
    handleContactUs,
    feedbackSubmitted,
  } = useContactUI();

  return (
    <BasicModal
      open={isContactUIOpen}
      handleClose={closeContactUI}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: { xs: 'none', sm: 'block' }, zIndex: 3000 }}
    >
      {isLoading ? (
        <Box my={4}>
          <Loading size="5rem" pt={5} />
        </Box>
      ) : (
        <>
          <Box display={'flex'} justifyContent={'space-between'}>
            <KeyopsHeader1>{contactUITitle}</KeyopsHeader1>
          </Box>
          <Typography
            id="modal-modal-description"
            fontSize="16px"
            fontWeight={400}
            marginTop={2}
            color={theme.palette.keyops.gray[700]}
          >
            {contactUIDescription}
          </Typography>
          {!feedbackSubmitted && (
            <>
              <CssTextField
                id="outlined-multiline-flexible"
                data-testid="feedback-textarea"
                multiline
                fullWidth
                minRows={3}
                sx={{ marginTop: 2.5 }}
                value={feedback}
                onChange={onFeedbackChange}
              />
              <Stack
                direction={'row'}
                gap={2}
                sx={{ float: 'right', marginTop: 3 }}
              >
                <Button
                  variant="outlined"
                  onClick={closeContactUI}
                  sx={{
                    fontSize: 16,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('general.cancel')}
                </Button>
                <Button
                  variant="contained"
                  disabled={!feedback}
                  onClick={() => handleContactUs(feedback)}
                  sx={{
                    fontSize: 16,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('general.submit')}
                </Button>
              </Stack>
            </>
          )}
        </>
      )}
    </BasicModal>
  );
};

export default ContactModal;
