import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import * as ROUTES from './utils/routes';
import { FEATURE_FLAGS } from './utils/fe-feature-flags';

// Pages
import { Login } from './pages/Login';
import Dashboard from './pages/Dashboard';
import Engagement from './pages/Engagement';
import { Earnings } from './pages/Earnings';
import ForgotEmail from './pages/ForgotEmail';
import ForgotPassword from './pages/ForgotPassword';
import Onboarding from './pages/Onboarding';
import ResetPassword from './pages/ResetPassword';
import SetupPassword from './pages/SetupPassword';
import ReferralLanding from './pages/ReferralLanding';
import Rewards from './pages/Rewards';
import Sandbox from './pages/Sandbox';
import SurveyDone from './pages/SurveyDone';
import VerifyWorkEmail from './pages/VerifyWorkEmail';
import Logout from './pages/Login/logout';
import NotFound from './pages/NotFound';
import { AdBoard } from './pages/AdBoard';
import { AdBoardSections } from './pages/AdBoardSections';

//HOC
import PrivateRoutes from './higher-order-components/PrivateRoutes';
import PublicRoutes from './higher-order-components/PublicRoutes';
import AppLayout from './higher-order-components/AppLayout';
import AdBoardLayout from './higher-order-components/AdBoardLayout';

// components
import Header from './components/Header';

import App from './App';
import { EngagementDisplay } from './pages/EngagementDisplay';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<App />}>
        {/* private routes goes here */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<AppLayout />}>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.EARNINGS} element={<Earnings />} />
            <Route path={ROUTES.REWARDS} element={<Rewards />} />
            <Route path={ROUTES.ENGAGEMENT} element={<Engagement />} />
            <Route path={ROUTES.SURVEY_DONE} element={<SurveyDone />} />
            <Route path={ROUTES.SANDBOX} element={<Sandbox />} />
            {/* TODO: enable the feature flag to see it */}
            {FEATURE_FLAGS.ADBOARD_ENABLED && (
              <Route path={ROUTES.ADBOARD_LANDING} element={<AdBoard />} />
            )}
            <Route path={ROUTES.LOGOUT} element={<Logout />} />
          </Route>
          {FEATURE_FLAGS.ADBOARD_ENABLED && (
            <Route path="/" element={<AdBoardLayout />}>
              <Route
                path={ROUTES.ADBOARD_SECTIONS}
                element={<AdBoardSections />}
              />
            </Route>
          )}
          {/* This is outside as it does not use a layout, we allow as much space as possible for surveys */}
          <Route
            path={ROUTES.ENGAGEMENT_DISPLAY}
            element={<EngagementDisplay />}
          />
        </Route>
        {/* Public routes goes here */}
        <Route element={<PublicRoutes />}>
          <Route index path={ROUTES.ROOT} element={<Login />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.FORGOT_EMAIL} element={<ForgotEmail />} />
          <Route path={ROUTES.ONBOARDING_LANDING} element={<Onboarding />} />
          {/* TODO: make sure this block of routes is still required */}
          <Route path={ROUTES.ONBOARDING_PATH} element={<Onboarding />} />
          {/* TODO: make sure this block of routes is still required */}
          <Route path={ROUTES.SURVEYS}>
            <Route path={ROUTES.ONBOARDING}>
              {/*Root level do normal private onboarding */}
              <Route index element={<Onboarding />} />
              {/* For users with accounts that have been invited to do onboarding */}
              <Route
                path={ROUTES.ONBOARDING_ID}
                element={
                  <>
                    <Header action="" text="" />
                  </>
                }
              />
            </Route>
          </Route>
          <Route path={ROUTES.ACCEPT_REFERRAL} element={<Onboarding />} />
        </Route>

        {/* TODO: figure out which routes are public and which are private */}
        <Route path={ROUTES.ONBOARDING_ROOT} element={<Onboarding />} />
        {/* ROUTES.REFERRAL - this route is just to support old invite links. We should remove it in few months.  */}
        <Route path={ROUTES.REFERRAL} element={<ReferralLanding />} />
        <Route path={ROUTES.SETUP_PASSWORD} element={<SetupPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.VERIFY_WORK_EMAIL} element={<VerifyWorkEmail />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Route>
    </>
  )
);

export default router;
