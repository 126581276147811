export type RewardOptionType = {
  name: string;
  description: string;
  logoFileName: string;
  category?: string;
};
type RewardOptionsType = {
  [key: string]: RewardOptionType[];
};

export const RewardOptions: RewardOptionsType = {
  blue: [],
  silver: [
    {
      name: 'YETI',
      description: 'Personalized Yeti drinkware',
      logoFileName: 'yeti.png', // This might change based on the file extension type
      category: '',
    },
    {
      name: 'JANNU',
      description: 'Personalized Premium Scrubs',
      logoFileName: 'jaanuu.png',
      category: '',
    },
  ],
  gold: [
    {
      name: 'Coach',
      description: '',
      logoFileName: 'coach.png',
      category: 'Clothing and jewelry',
    },
    // This is a US only option & we don't have country specific rewards capabilities yet
    // {
    //   name: 'Nike',
    //   description: '',
    //   logoFileName: 'nike.png',
    //   category: 'Clothing and jewelry',
    // },
    {
      name: 'StubHub',
      description: '',
      logoFileName: 'stubhub.png',
      category: 'Entertainment',
    },
    {
      name: 'Audible',
      description: '',
      logoFileName: 'audible.png',
      category: 'Entertainment',
    },
    {
      name: 'Apple',
      description: '',
      logoFileName: 'apple.png',
      category: 'Electronics',
    },
  ],
  platinum: [
    {
      name: 'United',
      description: '',
      logoFileName: 'united-airlines.png',
      category: 'Airlines',
    },
    {
      name: 'American Airlines',
      description: '',
      logoFileName: 'american-airlines.png',
      category: 'Airlines',
    },
    {
      name: 'Delta',
      description: '',
      logoFileName: 'delta.png',
      category: 'Airlines',
    },
    {
      name: 'Air Canada',
      description: '',
      logoFileName: 'air-canada.png',
      category: 'Airlines',
    },
    {
      name: 'Four Seasons',
      description: '',
      logoFileName: 'four-seasons.png',
      category: 'Hotels',
    },
    {
      name: 'The Ritz-Carlton',
      description: '',
      logoFileName: 'ritz-carlton.png',
      category: 'Hotels',
    },
    {
      name: 'St Regis',
      description: '',
      logoFileName: 'st-regis.png',
      category: 'Hotels',
    },
    {
      name: 'Lyft',
      description: '',
      logoFileName: 'lyft.png',
      category: 'Rides',
    },
    {
      name: 'Uber',
      description: '',
      logoFileName: 'uber.png',
      category: 'Rides',
    },
  ],
};

export const RewardTierNames = {
  blue: 'blue',
  silver: 'silver',
  gold: 'gold',
  platinum: 'platinum',
};
