/* eslint-disable @typescript-eslint/no-unused-vars */
// disabled linting since this is a "Sandbox" file
// External Dependencies
import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/system';
import { Backdrop, Box, Typography, Modal } from '@mui/material';

// Internal Dependencies
import { axiosCall } from '../../utils/api';

export const StatusContainer = styled('div')`
  position: fixed;
  top: 200px;
  margin: 10px;
  width: 200px;
  height: 200px;
`;

export default function Sandbox(): JSX.Element {
  // 0. Grab the theme
  // const theme: Theme = useTheme();
  const [status, setStatus] = React.useState('hello');

  const getData = async (): Promise<void> => {
    // 1. Grab the data, once
    const results = await axiosCall(true, 'get', 'status');

    setStatus(results.data.message);
  };

  useEffect(() => {
    console.log('use effect');
    getData();
  }, []);

  return (
    <StatusContainer>
      <div>{status}</div>
    </StatusContainer>
  );
}
