// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/';
import { EngagementCard } from './EngagementCard';
import { FilledBarChart } from '@keyops-hcp/ui-components';

type ActiveSurveyCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  duration: number;
  state: string;
  points?: number;
};

export const ActiveSurveyCard = ({
  engagementId,
  title,
  payout,
  duration,
  state,
  points,
}: ActiveSurveyCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  // 1. return Completed Engagement Row component
  return (
    <EngagementCard
      action={() => navigate(`/engagement/${engagementId}`)}
      actionLabel={
        state === 'in_progress'
          ? t('dashboard.activeEngagementsSection.resumeSurvey')
          : t('dashboard.activeEngagementsSection.startSurvey')
      }
      honorarium={payout}
      title={title}
      icon={<FilledBarChart />}
      iconBgColor={theme.palette.keyops.blue.keyopsBlue}
      type="survey"
      duration={duration}
      points={points}
    />
  );
};
