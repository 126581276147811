import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FiRadio } from 'react-icons/fi';

import i18next from '../../../../languages/i18n.config';
import { CallToActionBox } from '../CallToActionBox';

export const AdBoardOpenBox = ({
  endDate,
  adBoardId,
}: {
  endDate: string;
  adBoardId: string;
}) => {
  const { t } = i18next;
  const theme = useTheme();
  const navigate = useNavigate();

  const endDateDisplay = endDate
    ? ` until ${new Date(endDate).toLocaleDateString(i18next.language, {
        month: 'short',
        day: 'numeric',
      })}`
    : '';

  const joinAdBoard = () => {
    navigate(`/adboard/${adBoardId}/sections`);
  };
  return (
    <CallToActionBox
      icon={
        <FiRadio
          style={{
            color: theme.palette.keyops.teal,
            fontSize: '70px',
            flexShrink: 0,
          }}
        />
      }
      title={t('ad_board.landing_page.cta.open.title', {
        endDate: endDateDisplay,
      })}
    >
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Button
          variant='contained'
          sx={{
            backgroundColor: theme.palette.keyops.blue.midnightBlue,
            '&:hover': {
              backgroundColor: theme.palette.keyops.blue.midnightBlue50,
            },
          }}
          disableElevation
          onClick={joinAdBoard}
        >
          {t('ad_board.landing_page.cta.open.launch')}
        </Button>
      </Box>
    </CallToActionBox>
  );
};
