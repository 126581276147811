import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhysicianUserDto } from '@keyops-hcp/dtos';
import { ProfileField } from './ProfileField';
import {
  PhysicianPracticeSettingOptions,
  SpecialtyOptions,
  StateAndProvinceOptions,
  SubSpecialtyOptions,
} from '../../../../../utils/enum-display';

export const PhysiciansProfileFields = ({
  userData,
}: {
  userData: PhysicianUserDto;
}) => {
  const { t } = useTranslation();

  const physicianProfile = userData.hcpProfile;

  return (
    <>
      <ProfileField
        title={t('profile.profileDetails.memberSince')}
        content={new Date(userData.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      />
      <ProfileField
        title={t('profile.profileDetails.specialty')}
        content={
          physicianProfile.specialty && physicianProfile.specialty.length > 0
            ? physicianProfile.specialty
                .map((item) => SpecialtyOptions.getDisplay(item))
                ?.join(', ')
            : t('general.none')
        }
      />
      <ProfileField
        title={t('profile.profileDetails.subspecialty')}
        content={
          physicianProfile.subSpecialty &&
          physicianProfile.subSpecialty.length > 0
            ? physicianProfile.subSpecialty
                ?.map((item) => SubSpecialtyOptions.getDisplay(item))
                ?.join(', ')
            : t('general.none')
        }
      />
      <ProfileField
        title={t('profile.profileDetails.practiceSetting')}
        content={PhysicianPracticeSettingOptions.getDisplay(
          physicianProfile.practiceSetting
        )}
      />
      <ProfileField
        title={t('profile.profileDetails.locationOfPractice')}
        content={StateAndProvinceOptions.getDisplay(
          userData.provinceOfPractice
        )}
      />
    </>
  );
};
