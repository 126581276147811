// Reference - https://blog.teamtreehouse.com/increase-your-sites-performance-with-hardware-accelerated-css

export const hardwareAccelerationStyles = {
  WebkitTransform: 'translateZ(0)',
  MozTransform: 'translateZ(0)',
  msTransform: 'translateZ(0)',
  OTransform: 'translateZ(0)',
  transform: 'translateZ(0)',

  /* In Chrome and Safari we might see a flickering effect when using CSS transforms or animations.
  The following declarations can be used to fix the issue */
  WebkitBackfaceVisibility: 'hidden',
  MozBackfaceVisibility: 'hidden',
  msBackfaceVisibility: 'hidden',
  backfaceVisibility: 'hidden',
  WebkitPerspective: '1000',
  MozPerspective: '1000',
  msPerspective: '1000',
  perspective: '1000',
};
