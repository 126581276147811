import React from 'react';
import { Box, Button } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { DASHBOARD } from '../../utils/routes';

export const BackToDashboardButton = ({
  showBackButton,
}: {
  showBackButton?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="left"
      paddingX={0}
      paddingY={2}
    >
      <Button
        aria-label="back"
        startIcon={<FiChevronLeft />}
        onClick={() => navigate(DASHBOARD)}
        sx={{
          textTransform: 'none',
          ...(!showBackButton && { visibility: 'hidden' }),
        }}
      >
        {t('engagement.backToDashboardButton')}
      </Button>
    </Box>
  );
};
