import React from 'react';
import { Box, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  KeyOpsIcon,
  MoreHorizontal,
  Notifications,
} from '@keyops-hcp/ui-components';

import { DASHBOARD } from '../../../utils/routes';
import { ProfileNavSection } from './ProfileNavSection';

export const MobileTopNavBar = ({
  toggleMobileBottomNavDrawer,
}: {
  toggleMobileBottomNavDrawer: (newOpen: boolean) => void;
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Toolbar data-testid="mobile-top-nav-bar">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        margin={0}
        padding={0}
      >
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Box width={33} height={33} onClick={() => navigate(DASHBOARD)}>
            <KeyOpsIcon />
          </Box>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={-1}
        >
          <Notifications />
          <ProfileNavSection />

          <IconButton
            data-testid="drawer-menu-button"
            aria-label="drawer-menu-button"
            sx={{
              p: 0,
            }}
            onClick={() => toggleMobileBottomNavDrawer(true)}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <MoreHorizontal
              sx={{
                fontSize: '24px',
                color: palette.keyops.blue.midnightBlue,
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Toolbar>
  );
};
