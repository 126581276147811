import React from 'react';
import {
  alpha,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useMatch } from 'react-router';

import { NavItemType } from '../../custom-hooks/useNavConstants';
import { hoverItemStyle } from '@keyops-hcp/ui-components';

interface NavItemProps {
  item: NavItemType;
  handlePathClick: () => (event: React.MouseEvent) => void;
  isMobileBottomNavbar?: boolean; // different styling for mobile nav bar
  isMobileBottomDrawer?: boolean; // different styling for contact and logout mobile drawer
}

export const NavItem: React.FC<NavItemProps> = ({
  item,
  handlePathClick,
  isMobileBottomNavbar = false,
  isMobileBottomDrawer = false,
}) => {
  const theme = useTheme();

  // To handle default active tab
  const match = useMatch(`${item.path}/*`);

  return (
    <ListItem
      key={item.name}
      sx={{
        display: 'block',
        px: 0,
        py: isMobileBottomNavbar ? 0 : 0.5,
        color: theme.palette.keyops.blue.lightSteelBlue,
      }}
    >
      <ListItemButton
        onClick={handlePathClick()}
        color={theme.palette.keyops.blue.lightSteelBlue}
        disableRipple // Disable default animation
        sx={{
          display: 'flex',
          flexDirection: isMobileBottomNavbar ? 'column' : 'row',
          minHeight: 48,
          borderRadius: isMobileBottomNavbar ? 0 : 2,
          color: match
            ? theme.palette.keyops.blue.keyopsBlue
            : theme.palette.keyops.blue.lightSteelBlue,
          bgcolor:
            !!match &&
            !isMobileBottomNavbar &&
            alpha(theme.palette.keyops.blue.arcticBlue, 0.2),
          boxShadow:
            !!match &&
            !isMobileBottomNavbar &&
            '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          '&:active': {
            bgcolor:
              !isMobileBottomNavbar &&
              alpha(theme.palette.keyops.blue.arcticBlue, 0.2),
            boxShadow:
              !isMobileBottomNavbar && '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
            color: theme.palette.keyops.blue.keyopsBlue,
            '.MuiListItemIcon-root': {
              color: theme.palette.keyops.blue.keyopsBlue,
            },
            '.MuiListItemText-root': {
              fontWeight: 500,
            },
          },
          ...(!isMobileBottomNavbar && hoverItemStyle()),
        }}
      >
        <ListItemIcon
          data-testid="nav-item-icon"
          sx={{
            fontSize:
              isMobileBottomNavbar && item.name === 'Rewards' ? '26px' : '24px',
            minWidth: 0,
            mr: isMobileBottomNavbar ? 0 : 2,
            justifyContent: 'center',
            color: isMobileBottomDrawer
              ? theme.palette.keyops.blue.midnightBlue
              : 'inherit',
          }}
        >
          <item.icon
            fontSize={
              isMobileBottomNavbar && item.name === 'Rewards' ? '26px' : '24px'
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{
            fontSize: isMobileBottomNavbar ? '10px' : '14px',
            fontWeight:
              isMobileBottomNavbar || isMobileBottomDrawer ? 400 : 500,
            color: isMobileBottomDrawer && theme.palette.keyops.black.main,
          }}
          sx={{
            mb: isMobileBottomNavbar && 0,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
