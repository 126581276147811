// External Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';

import BlueTier from '../../images/Blue-Tier.png';
import SilverTier from '../../images/Silver-Tier.png';
import GoldTier from '../../images/Gold-Tier.png';
import PlatinumTier from '../../images/Platinum-Tier.png';

const RewardsBadge = ({
  tier,
  width,
  height,
}: {
  tier?: string;
  width?: string;
  height?: string;
}) => {
  const { t } = useTranslation();

  const tierMap = {
    blue: BlueTier,
    silver: SilverTier,
    gold: GoldTier,
    platinum: PlatinumTier,
  };

  return (
    <img
      src={tierMap[tier]}
      alt={t(`rewardsBadge.${tier}`)}
      style={{ border: 'none', width: width, height: height }}
    />
  );
};

export default RewardsBadge;
