// External Dependencies
import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Control, Controller, get } from 'react-hook-form';

// Internal Dependencies
import { Options } from '../forms/form-constants';

type KeyOpsRadioButtonGroupProps = RadioGroupProps & {
  fieldName: string;
  placeholder: string;
  defaultValue?: string;
  rules?: { [key: string]: string }; // validation rules
  options?: Options;
  control: Control;
  helperText?: string;
  errors: object;
};

const KeyOpsRadioButtonGroup = ({
  fieldName,
  placeholder,
  defaultValue = '',
  rules,
  options = [],
  control,
  helperText,
  errors,
  ...props
}: KeyOpsRadioButtonGroupProps & FormControlProps) => {
  const theme = useTheme();
  const error = get(errors, fieldName);

  const renderInput = ({ field }) => (
    <RadioGroup
      aria-labelledby="radios"
      name={fieldName}
      {...field}
      sx={{ mt: 2 }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );

  return (
    <FormControl error={!!error} {...props}>
      <Typography color={theme.palette.keyops.black.main}>
        {placeholder}
      </Typography>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={renderInput}
        {...props}
      />
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
      {error && <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default KeyOpsRadioButtonGroup;
