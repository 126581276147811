import React from 'react';
import { Stack, Toolbar, useTheme } from '@mui/material';

import { Notifications } from '@keyops-hcp/ui-components';

import { ProfileNavSection } from './ProfileNavSection';
import { EarningsNavSection } from './EarningsNavSection';
import { RewardsNavSection } from './RewardsNavSection';

export const DesktopTopAppBar = ({ scrolled }: { scrolled: boolean }) => {
  const { palette } = useTheme();

  return (
    <Toolbar
      data-testid="desktop-top-navbar"
      sx={{
        justifyContent: 'flex-end',
        borderBottom: scrolled
          ? `1px solid ${palette.keyops.blue.lightSteelBlue20}`
          : `none`,
        background: palette.keyops.white.main,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={2}
        pt={2}
        pb={2}
      >
        <EarningsNavSection />
        <RewardsNavSection />
        <Notifications />
        <ProfileNavSection />
      </Stack>
    </Toolbar>
  );
};
