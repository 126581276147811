import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { axiosCall } from '../../utils/api';
import ToastContext from '../../components/Toast/ToastContext';

interface ContactUIContextType {
  isContactUIOpen: boolean;
  openContactUI: () => void;
  closeContactUI: (event?: object, reason?: string) => void;
  contactUITitle: string;
  contactUIDescription: string;
  feedback: string;
  onFeedbackChange: (e: ChangeEvent) => void;
  isLoading: boolean;
  handleContactUs: (text: string) => void;
  feedbackSubmitted: boolean;
}

const ContactUIContext = createContext<ContactUIContextType | undefined>(
  undefined
);

export const useContactUI = (): ContactUIContextType => {
  const { t } = useTranslation();

  const context = useContext(ContactUIContext);
  if (!context) {
    throw new Error(t('contact.contextProviderError'));
  }
  return context;
};

interface ContactUIProviderProps {
  children: ReactNode;
}

export const ContactUIProvider: React.FC<ContactUIProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  const [isContactUIOpen, setIsContactUIOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openContactUI = () => {
    setIsContactUIOpen(true);
  };

  const closeContactUI = (event, reason) => {
    if (reason === 'backdropClick' && !feedbackSubmitted) return;
    setIsContactUIOpen(false);
    setFeedbackSubmitted(false);
    setFeedback('');
  };

  const onFeedbackChange = (e) => setFeedback(e.target.value);
  const { triggerToast } = useContext(ToastContext);

  const handleContactUs = async (text) => {
    setIsLoading(true);
    try {
      const response = await axiosCall(
        false,
        'post',
        'feedback',
        false,
        false,
        {
          text,
        }
      );

      if (response.status >= 200 && response.status <= 299) {
        setIsLoading(false);
        setFeedbackSubmitted(true);
        setFeedback('');
      } else {
        triggerToast({
          type: 'error',
          message: response?.message ?? t('general.errorText'),
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const contactUITitle = !feedbackSubmitted
    ? t('contact.title')
    : t('contact.success.title');

  const contactUIDescription = !feedbackSubmitted
    ? t('contact.description')
    : t('contact.success.description');

  return (
    <ContactUIContext.Provider
      value={{
        isContactUIOpen,
        openContactUI,
        closeContactUI,
        contactUITitle,
        contactUIDescription,
        feedback,
        onFeedbackChange,
        isLoading,
        handleContactUs,
        feedbackSubmitted,
      }}
    >
      {children}
    </ContactUIContext.Provider>
  );
};
