// External Dependencies
import React from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material/';
import { FiShare, FiLink, FiMail } from 'react-icons/fi';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import useReferralShareOptions from '../../custom-hooks/useReferralShareOptions';

const DesktopButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '225px',
  margin: '0 auto 0 auto',
  [theme.breakpoints.down('sm')]: {
    display: navigator.share ? 'none' : 'flex',
    marginBottom: '20px',
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  textTransform: 'none',
  borderRadius: '6px',
  display: navigator.share ? 'flex' : 'none',
  margin: '8px auto 0 auto',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const ModalShareIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '50px',
  minWidth: '50px',
  height: '50px',
  minHeight: '50px',
  borderRadius: '25px',
  background: theme.palette.keyops.blue.arcticBlue,
  '& svg': {
    width: '25px',
    height: '25px',
  },
}));

const PostEngagementShareIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '56px',
  minWidth: '56px',
  height: '56px',
  minHeight: '56px',
  borderRadius: '28px',
  background: theme.palette.keyops.white.main,
  '& svg': {
    width: '30px',
    height: '30px',
  },
}));

const ShareEngagement = ({
  engagementId,
  postEngagement,
}: {
  engagementId: number | boolean;
  closeReferral: (engagementId: number | boolean) => void;
  postEngagement?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    shareReferralBody,
    mobileShare,
    handleCopyClick,
    linkCopied,
    mailToClick,
  } = useReferralShareOptions(engagementId);

  const ShareIcon = postEngagement ? PostEngagementShareIcon : ModalShareIcon;

  // By default engagementId will be false, prevents misfiring
  if (isLoading || engagementId === false) {
    return (
      <Box
        sx={{ minHeight: { xs: '220px', md: '200px' } }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircularProgress aria-label="progress" size="5em" />
      </Box>
    );
  }

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Typography
        aria-label="share"
        fontSize={postEngagement ? '16px' : '18px'}
        variant="h6"
        padding={'10px'}
        component="h2"
        fontWeight={'600'}
        textTransform={'capitalize'}
        color={'keyops.gray.700'}
      >
        {t('share.title')}
      </Typography>
      <Typography
        sx={{
          padding: '0px 10px 10px 10px',
        }}
        fontSize="16px"
        color={'keyops.gray.700'}
      >
        {shareReferralBody}
      </Typography>
      <ShareButton
        onClick={() => mobileShare()}
        variant="contained"
        startIcon={<FiShare />}
      >
        {t('share.share')}
      </ShareButton>
      <DesktopButtons>
        <Stack
          direction={'column'}
          alignItems={'center'}
          padding={'8px'}
          onClick={handleCopyClick}
        >
          <ShareIcon>
            <FiLink color="primary" />
          </ShareIcon>
          <Typography
            fontSize="14px"
            paddingTop={'4px'}
            color={'keyops.gray.700'}
          >
            {linkCopied ? t('share.copiedLink') : t('share.copyLink')}
          </Typography>
        </Stack>
        <Stack
          direction={'column'}
          alignItems={'center'}
          padding={'8px'}
          onClick={mailToClick}
        >
          <ShareIcon>
            <FiMail color="primary" />
          </ShareIcon>
          <Typography
            paddingTop={'4px'}
            fontSize="14px"
            color={'keyops.gray.700'}
          >
            {t('share.email')}
          </Typography>
        </Stack>
      </DesktopButtons>
    </>
  );
};

export default ShareEngagement;
