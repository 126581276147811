import React, { useContext, useEffect, useState } from 'react';
import RewardsHome from './RewardsHome';
import { UserContext } from '../../UserContext';
import { UserDto } from '@keyops-hcp/dtos';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../utils/routes';
import Loading from '../../components/Loading';

const Rewards = () => {
  const navigate = useNavigate();
  const context = useContext(UserContext);

  const [userData, setUserData] = useState<UserDto>({} as UserDto);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    context.getData({
      setUserData: setUserData,
      setLoadingData: setLoadingData,
    });
  }, []);

  if (loadingData) {
    return <Loading />;
  }
  if (!loadingData && userData.hcpType !== 'physician') navigate(DASHBOARD);
  return <RewardsHome />;
};

export default Rewards;
