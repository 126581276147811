import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../UserContext';
import ToastContext from '../../components/Toast/ToastContext';
import { useAuth } from '../../higher-order-components/useAuth';
import { useNavigate } from 'react-router';
import { TOAST_TYPES } from '../../utils/constants';
import { LOGIN } from '../../utils/routes';
import { useTranslation } from 'react-i18next';

const Logout = (): JSX.Element => {
  const { authed, logout } = useAuth();
  const context = useContext(UserContext);
  const { triggerToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const signOut = async () => {
      await logout();
      context.setData({});
      triggerToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('logout.success'),
      });
      navigate(LOGIN);
    };
    if (authed) signOut();
  }, [authed, context, logout, navigate, triggerToast]);

  return <></>;
};

export default Logout;
