// External Dependencies
import { Button, Paper } from '@mui/material/';
import { styled } from '@mui/system';

// Interanl Dependencies
import { mobileBreakPoint } from '../utils/constants';

export const RoundedButton = styled(Button)`
  border-radius: 20px;
  text-transform: capitalize;
  padding: 7.75px;
  max-width: 400px;
`;

export const PageContainer = styled(Paper)`
  margin: auto;
  padding: 40px;
  @media (min-width: ${mobileBreakPoint}) {
    padding-top: 115px;
    width: calc(100% - 50px);
    max-width: 600px;
  }
`;
