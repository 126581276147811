// External Dependencies
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Control, Controller, get } from 'react-hook-form';
import { Box, FormHelperText, useMediaQuery, useTheme } from '@mui/material';

// Internal Dependencies

type KeyOpsDatePickerProps = {
  fieldName: string;
  control: Control;
  rules?: { [key: string]: string };
  placeholder: string;
  errors: object;
};

const KeyOpsDatePicker = ({
  fieldName,
  placeholder,
  control,
  rules,
  errors,
}: KeyOpsDatePickerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const error = get(errors, fieldName);
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={fieldName}
          control={control}
          rules={rules}
          render={({ field }) => (
            <DatePicker
              label={placeholder}
              {...field}
              value={field.value || null}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  error: !!error,
                },
              }}
              sx={{
                backgroundColor: isMobile && theme.palette.keyops.white.main,
              }}
            />
          )}
        />
        {error && (
          <FormHelperText error sx={{ ml: 0 }}>
            {error.message}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default KeyOpsDatePicker;
