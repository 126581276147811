import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { UserDto } from '@keyops-hcp/dtos';

import { getGreeting } from '../../../utils/functions/common-utils';
import { KeyopsHeader1 } from '@keyops-hcp/ui-components';

type DashboardHeaderContentProps = {
  userData: UserDto;
};

const getDisplayTitle = (userData: UserDto): string => {
  let displayName = '';
  if (userData.hcpType === 'physician') {
    displayName = `, Dr. ${userData?.lastName}`;
  } else {
    displayName = `, ${userData?.firstName}`;
  }
  return `${getGreeting()}${displayName}`;
};

const DashboardHeaderContent = ({ userData }: DashboardHeaderContentProps) => {
  const displayTitle = useMemo(() => getDisplayTitle(userData), [userData]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent={'space-between'}
      alignItems={{ xs: 'left', sm: 'center' }}
      spacing={2}
    >
      <Box>
        <KeyopsHeader1
          className="suspendAfterTwoLines"
          sx={{
            marginTop: '8px',
          }}
        >
          {displayTitle}
        </KeyopsHeader1>
      </Box>
    </Stack>
  );
};

export default DashboardHeaderContent;
