export const getActiveInvitations = (invitations) => {
  const activeInvitations = invitations.filter(
    (invitation) =>
      ['new', 'in_progress'].includes(invitation.state) &&
      invitation.engagement.state === 'published' &&
      (invitation.engagement.endsAt === null ||
        new Date() < new Date(invitation.engagement.endsAt))
  );
  activeInvitations.sort((a, b) => {
    if (a.state === b.state) {
      return b.createdAt - a.createdAt;
    }
    if (a.state === 'in_progress') {
      return -1;
    }
    return 1;
  });
  return activeInvitations;
};

export const getActiveAdBoardInvitations = (invitations) => {
  const activeInvitations = invitations.filter(
    (invitation) =>
      ['sent', 'pending-contract', 'confirmed'].includes(invitation.state)
    //TODO: check adboard state
  );
  activeInvitations.sort((a, b) => {
    if (a.state === b.state) {
      return b.createdAt - a.createdAt;
    }
    return 1;
  });
  return activeInvitations;
};

export const getCompletedInvitations = (invitations) => {
  return invitations.filter(
    (invitation) =>
      invitation.state === 'completed' && !isInvitationReferable(invitation)
  );
};

export const getCompletedInvitationsWithReferrals = (invitations) => {
  return invitations.filter((invitation) => isInvitationReferable(invitation));
};

const isInvitationReferable = (invitation) => {
  return (
    invitation.engagement.referralEnabled &&
    invitation.engagement.state === 'published' &&
    invitation.state === 'completed' &&
    (!invitation.engagement.endsAt ||
      new Date() < new Date(invitation.engagement.endsAt))
  );
};

// TODO: check if we can rm this one and use `getTotalEarnings`
// defined in src/pages/Earnings/index.tsx after the redesign of the dashboard
export const getTotalEarnings = (engagementData) => {
  let totalEarnings = 0;
  engagementData.surveyInvitations
    .filter((invitation) => invitation.state === 'completed')
    .forEach((invitation) => {
      totalEarnings += invitation.engagement.payoutValue;
    });
  return totalEarnings;
};
