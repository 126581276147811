// External Dependencies
import React from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
export const LogoImage = styled('img')({
  width: '160px',
});

export const MarketingImage = styled('img')({
  width: '90%',
  display: 'inline-block',
  alignSelf: 'center',
});

// TODO: replace with PrimaryTooltip in ui-components
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
  },
});
